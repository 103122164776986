import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/footer/Footer'
import EditorJournalDetails from '../../components/journals/editorJournalsList'

const EditorDetailsPage = () => {
  return (
    <div>
        <Header/>
        <EditorJournalDetails/>
        <Footer/>
    </div>
  )
}

export default EditorDetailsPage