import React from 'react'
import Header from '../../components/Header/Header'
import EditorGuidelines from '../../components/guidelines/EditorGuidelines'
import Footer from '../../components/footer/Footer'

const EditorGuidelinesPage = () => {
  return (
    <>
    <Header/>
    <EditorGuidelines/>
    <Footer/>
    </>
  )
}

export default EditorGuidelinesPage