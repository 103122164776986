import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import SubmissionPage from "../submissionPage/SubmissionPage";
import bannerBg from "../../assets/images/banner-guideless.png";

const EditorGuidelines = () => {
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center text-center text-warning"
        style={{
          backgroundImage: `url(${bannerBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "200px",
          width: "100%",
        }}
      >
        <h3 className="fw-bold text-white"> Editor Guidelines</h3>
      </div>

      <Container className="pt-3 pb-3">
        <p className='pb-3 pt-3'>
          At <strong>Science Photons Publishers</strong>, we are dedicated to
          advancing scientific knowledge and fostering an open-access platform
          that supports authors and readers alike. Our commitment to integrity,
          transparency, and excellence guides every aspect of our journal. As an
          editor, you play a crucial role in this mission. You are responsible
          for upholding our standards by ensuring that all submissions undergo a
          rigorous peer-review process, providing constructive feedback, and
          maintaining ethical oversight. Together, we aim to create a scholarly
          environment that promotes high-quality research and empowers authors
          to share their work with the global community.
        </p>

        <div
          className="p-5 mb-4"
          style={{
            backgroundColor: '#FFFDF7',
            boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.16)',
            borderRadius: '8px',
            margin: '0 auto',
          }}
        >
          <h4 style={{ fontWeight: 600, fontSize: '20px' }}>1. Role and Responsibilities</h4>
          <ul>
            <li>
              <strong>Evaluate Submissions:</strong>
              <ul>
                <li>
                  Assess each manuscript to ensure it aligns with the journal's
                  scope and quality standards.
                </li>
                <li>
                  If a submission doesn’t fit, return it promptly with a brief
                  explanation.
                </li>
              </ul>
            </li>
            <li>
              <strong>Assign Reviewers:</strong>
              <ul>
                <li>
                  Select qualified peer reviewers based on their expertise and
                  relevance to the manuscript.
                </li>
                <li>
                  Consider the reviewers’ availability to ensure timely feedback.
                </li>
              </ul>
            </li>
            <li>
              <strong>Ensure Timely Review:</strong>
              <ul>
                <li>Monitor the peer-review process closely.</li>
                <li>
                  Aim for reviews to be completed within 4-6 weeks. If delays
                  occur, follow up with the reviewers.
                </li>
              </ul>
            </li>
            <li>
              <strong>Final Decision:</strong>
              <ul>
                <li>
                  Make the final editorial decision based on feedback from
                  reviewers and the quality of the manuscript.
                </li>
                <li>
                  Provide constructive feedback in the decision letter, regardless
                  of whether the manuscript is accepted, requires revisions, or is
                  rejected.
                </li>
              </ul>
            </li>
            <li>
              <strong>Ethical Oversight:</strong>
              <ul>
                <li>
                  Ensure all manuscripts meet ethical standards, including checks
                  for originality and plagiarism.
                </li>
                <li>
                  Confirm proper disclosures regarding conflicts of interest and
                  ethical approvals for human and animal studies.
                </li>
              </ul>
            </li>
          </ul>

          <h4 style={{ fontWeight: 600, fontSize: '20px' }}>2. Communication</h4>
          <ul>
            <li>
              <strong>With Authors:</strong>
              <ul>
                <li>
                  Provide clear and constructive feedback to authors at each stage
                  of the review process.
                </li>
                <li>
                  Personalize communication to create a supportive relationship.
                </li>
              </ul>
            </li>
            <li>
              <strong>With Reviewers:</strong>
              <ul>
                <li>Maintain confidentiality throughout the review process.</li>
                <li>
                  Encourage balanced and fair reviews, and provide guidance as
                  needed.
                </li>
              </ul>
            </li>
            <li>
              <strong>With the Editorial Board:</strong>
              <ul>
                <li>
                  Collaborate with fellow board members to promote the journal's
                  growth and align with its mission.
                </li>
                <li>
                  Hold regular meetings to discuss ongoing issues and share
                  insights.
                </li>
              </ul>
            </li>
          </ul>

          <h4 style={{ fontWeight: 600, fontSize: '20px' }}>3. Manuscript Integrity</h4>
          <ul>
            <li>
              <strong>Preparation Standards:</strong>
              <ul>
                <li>
                  Ensure manuscripts follow the journal's author guidelines for
                  formatting, references, and figures.
                </li>
                <li>
                  Clarify any specific requirements, such as citation styles.
                </li>
              </ul>
            </li>
            <li>
              <strong>Review Ethical Statements:</strong>
              <ul>
                <li>
                  Check that ethical statements regarding human and animal rights
                  are included.
                </li>
                <li>Ensure appropriate ethical approvals are mentioned.</li>
              </ul>
            </li>
          </ul>

          <h4 style={{ fontWeight: 600, fontSize: '20px' }}>4. Confidentiality</h4>
          <ul>
            <li>
              All manuscripts and related communications must remain confidential.
            </li>
            <li>
              Editors should not disclose any details about the manuscript to
              anyone outside the review process.
            </li>
          </ul>

          <h4 style={{ fontWeight: 600, fontSize: '20px' }}>5. Conflict of Interest</h4>
          <ul>
            <li>
              Editors must avoid any conflicts of interest and disclose any
              potential biases.
            </li>
            <li>
              Recuse yourself from handling any manuscripts where a conflict
              arises, such as financial or personal relationships that could
              influence your judgment.
            </li>
          </ul>
        </div>

        <div
          className="p-4 text-center"
          style={{
            border: '1px solid #000',
            borderRadius: '8px',
            maxWidth: '600px',
            margin: '0 auto',
          }}
        >
          <h4 style={{ fontWeight: 600 }} className="mb-3">
            Join as an Editorial Member
          </h4>
          <p className="mb-3">
            If you are interested in joining as an editorial member, please visit the 
          </p>
          <Link to="/editorjoiningpage">
            <Button style={{backgroundColor:'#095F60'}}>Join now</Button>
          </Link>
        </div>
      </Container>
      <SubmissionPage />
    </>
  );
};

export default EditorGuidelines;
