import React, { useState, useEffect } from "react";
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";
import { DataTable } from "../../../components/tables";
import { useNavigate } from "react-router-dom";
import { articlesDataGridCols } from "./data-grid-cols";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import moment from "moment";
import Swal from "sweetalert2";

const UserArticlesList = () => {
  const [searchTitle, setSearchTitle] = useState("");
  const navigate = useNavigate();
  const [articleData, setArticleData] = useState<any>([]);
  const [filteredArticles, setFilteredArticles] = useState<any>([]);

  const loginData: any = sessionStorage.getItem("loginData");
  const userData = JSON.parse(loginData);

  const handleCancel = () => {
    setSearchTitle("");
    setFilteredArticles(articleData);
  };

  const handleSearch = () => {
    const filteredData = articleData.filter((article: any) =>
      article.title.toLowerCase().includes(searchTitle.toLowerCase())
    );
    setFilteredArticles(filteredData);
  };

  const handleCreateArticle = () => {
    navigate("/user/createarticle");
  };

  useEffect(() => {
    getArticleData();
  }, []);

  const getArticleData = () => {
    const data = {
      articleId: userData?.journalName?._id,
      currentIssues: false,
      previousIssues: false
    };

    AdminService.getArticle(data).then((res) => {
      if (res.status === "Success") {
        const formattedData = res.data.map((item: any) => ({
          ...item,
          createdAt: moment(item.createdAt).format('YYYY-MM-DD'),
        }));
        setArticleData(formattedData);
        setFilteredArticles(formattedData); // Set initial filtered data
      } else {
        toast.error(res.message);
      }
    });
  };

  const deleteArticlesInfo = (articleId: string) => {
    Swal.fire({
      title: "Are you sure you want to delete Articles Info",
      text: "Once deleted, you won't be able to recover this entry!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F5BE18",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = { articleId };

        AdminService.deleteArticle(payload).then((res: any) => {
          if (res.status === "Success") {
            toast.success(res.message);
            getArticleData();
          } else {
            toast.error(res.message);
          }
        });
      }
    });
  };

  const handleEditArticles = (data: any) => {
    navigate("/user/editarticles", { state: data });
  };

  const handleAccessIssue = (type: any, data: any) => {
    const payload = {
      articleId: data._id,
      typeSelection: type,
    };
    AdminService.updateArticleIssues(payload).then((res: any) => {
      if (res.status === "Success") {
        setArticleData((prevData: any) =>
          prevData.filter((item: any) => item._id !== data._id)
        );
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <b>Articles List</b>
                </label>
                <div className="d-flex">
                  <input
                    name="Article Title"
                    onChange={(e: any) => setSearchTitle(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={searchTitle}
                    placeholder="Enter the Article Title"
                  />
                  <button
                    className="btn btn-primary rounded px-4 py-1"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="text-end">
                  <button
                    className="btn btn-success"
                    onClick={handleCreateArticle}
                  >
                    Add Article
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <DataTable
                tableData={filteredArticles}
                TableCols={articlesDataGridCols}
                deleteArticlesInfo={deleteArticlesInfo}
                handleEditArticles={handleEditArticles}
                handleAccessIssue={handleAccessIssue}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserArticlesList;
