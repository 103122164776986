import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/footer/Footer'
import Archive from '../../components/journals/archive'

const ArchivePage = () => {
    return (
        <div>
            <Header />
            <Archive />
            <Footer />
        </div>
    )
}

export default ArchivePage