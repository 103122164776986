import { DataTableCol } from "../../../components/tables/types";

export const EditorJoiningDataGridCols: DataTableCol[] = [
    {
        title: 'S.no',
        control: 'serialNo',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Editor Name',
        control: 'editorName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'University Name',
        control: 'universityName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Editor Dept',
        control: 'editorDepartment',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Editor Type',
        control: 'editorType',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        canShowColumn: true
    },
];