

import React, { useEffect, useState } from "react";
import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


// interface ThreeCirclesLoaderProps {
//   data?: { login?: boolean }; // Optional prop with a login field
// }

// const ThreeCirclesLoader: React.FC<ThreeCirclesLoaderProps> = ({ data = {} }) => {
//   const [loginData, setLoginData] = useState(false);

//   useEffect(() => {
//     setLoginData(data?.hasOwnProperty("login") ?? false); // Safely check for 'login' property
//   }, [data]);

//   return (
//       <div className={loginData ? "" : "text-center p-5 custom_spinner"}>
//         <div className={loginData ? "loader" : "loader loader-width"}></div>
//       </div>
    
//   );
// };

const ThreeCirclesLoader = () => (<div className="text-center">
  <Spinner animation="border" role="status">
    <span className="visually-hidden">Loading...</span>
  </Spinner>
</div>)

export default ThreeCirclesLoader;
