import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useNavigate,useLocation } from "react-router-dom";
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";

const OpenEditorJoining = () => {
  const navigate = useNavigate();
  const {state}=useLocation();

  // State to hold form values
  const [formData, setFormData] = useState<any>({
    editorType: "",
    editorName: "",
    editorQualification: "",
    editorDesignation: "",
    editorDepartment: "",
    universityName: "",
    country: "",
    cityState: "",
    editorEmail: "",
    editorPhone: "",
    editorFax: "",
    editorBiography: "",
    editorResearchInterest: "",
    editorPhoto: null,
  });

  useEffect(()=>{
   if(state){
    setFormData(state)
   }
  },[state])

  const handleCancel=()=>{
    navigate('/admin/editorJoiningList')
  }

  // Handle field changes and update form data
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { id, value, files }: any = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [id]: files ? files[0] : value, // Handle file input separately
    }));
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
              <Form>
                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group controlId="editorType">
                      <Form.Label>Editor Type *</Form.Label>
                      <Form.Control
                        as="select"
                        value={formData.editorType}
                        onChange={handleChange}
                        required
                      >
                        <option value="">--Editor Type--</option>
                        <option>Editor</option>
                        <option>Associate Editor</option>
                        <option>Assistant Editor</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please select an editor type.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="editorName">
                      <Form.Label>Editor Name *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Editor Name"
                        value={formData.editorName}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter editor name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group controlId="editorQualification">
                      <Form.Label>
                        Editor Qualification (Eg. MD, PhD) *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Editor Qualification"
                        value={formData.editorQualification}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter qualification.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="editorDesignation">
                      <Form.Label>Editor Designation *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Eg: Professor, Associate Professor, etc."
                        value={formData.editorDesignation}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter designation.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group controlId="editorDepartment">
                      <Form.Label>Editor Department *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Editor Department"
                        value={formData.editorDepartment}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter department.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="universityName">
                      <Form.Label>University Name *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="University Name"
                        value={formData.universityName}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter university name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group controlId="country">
                      <Form.Label>Country *</Form.Label>
                      <Form.Control
                        as="select"
                        value={formData.country}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Country</option>
                        <option>USA</option>
                        <option>India</option>•••••••••••
                        <option>UK</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please select a country.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="cityState">
                      <Form.Label>City or State </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Eg: City or State Name"
                        value={formData.cityState}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group controlId="editorEmail">
                      <Form.Label>Editor E-Mail *</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Editor E-Mail"
                        value={formData.editorEmail}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="editorPhone">
                      <Form.Label>Editor Phone </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Editor Phone"
                        value={formData.editorPhone}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group controlId="editorFax">
                      <Form.Label>Editor Fax</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Editor Fax"
                        value={formData.editorFax}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="editorPhoto">
                      <Form.Label>Editor Photo</Form.Label>
                      <Form.Control type="file" onChange={handleChange} />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group controlId="editorBiography">
                      <Form.Label>Editor Biography (Optional)</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Biography..."
                        value={formData.editorBiography}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group controlId="editorResearchInterest">
                      <Form.Label>
                        Editor Research Interest (Optional)
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Research Interest..."
                        value={formData.editorResearchInterest}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

             
                <Button type="submit" variant="success" onClick={handleCancel}>
                    Cancel
                </Button>
              </Form>
    
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenEditorJoining;
