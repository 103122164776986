import React, { useState, useEffect } from "react";
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";
import { DataTable } from "../../../components/tables";
import { useNavigate } from "react-router-dom";
import { EditorsDataGridCols } from "./data-grid-cols";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import moment from "moment";

const EditorList = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [registersData, setRegistersData] = useState([]);
  const [filteredRegisters, setFilteredRegisters] = useState([]); 

  let usersData: any = [];

  const handleCancel = () => {
    setName("");
    setFilteredRegisters(registersData);
  };

  const handleSearch = () => {
    const filteredData = registersData.filter((register: any) =>
      register.name.toLowerCase().includes(name.toLowerCase())
    );
    setFilteredRegisters(filteredData);
  };
  const handleCreateUser = () => {
    navigate("/admin/createEditorial");
  };

  useEffect(() => {
    getEditors();
  }, []);
  const getEditors = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
      type: "ambassador",
    };
  
    AdminService.getAllEditors(data).then((res: any) => {
      if (res.status === "Success") {
        if (res.data) {
          const formattedData = res.data.map((item: any) => {
            return {
              ...item,
              createdAt: moment(item.createdAt).format('YYYY-MM-DD'), 
            };
          });
          setRegistersData(formattedData);
          setFilteredRegisters(formattedData); 
        }
      } else {
        toast.error(res.message);
      }
    });
  };
  

  const deleteEditorListInfo = (editorId: string) => {
    const payload = {
      editorId: editorId,
    };
    AdminService.deleteEditor(payload).then((res: any) => {
      if (res.status === "Success") {
        toast.success(res.message);
        getEditors();
      } else {
        toast.error(res.message);
      }
    });
  };
  const handleEditUser = (data: any) => {
    navigate("/admin/editorBoard", { state: data });
  };


  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <b>Editor List</b>
                </label>
                <div className="d-flex">
                  <input
                    name="User Name"
                    onChange={(e: any) => setName(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={name}
                    placeholder="Enter the User Name"
                  />
                  <button
                    className="btn btn-primary rounded px-4 py-1"
                    onClick={() => handleSearch()}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="text-end">
                  <button
                    className="btn btn-success"
                    onClick={handleCreateUser}
                  >
                    Create Editor Board
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <DataTable
                tableData={filteredRegisters}
                TableCols={EditorsDataGridCols}
                deleteEditorListInfo={deleteEditorListInfo}
                handleEditUser={handleEditUser}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorList;

