import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import SubmissionPage from "../submissionPage/SubmissionPage";
import bannerBg from "../../assets/images/banner-guideless.png";
import ContactInformation from "../contactInformation/ContactInfo";

const Supportus = () => {
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center text-center text-warning"
        style={{
          backgroundImage: `url(${bannerBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "200px",
          width: "100%",
        }}
      >
        <h3 className="fw-bold text-white">Open Access</h3>
      </div>
      <Container className="pt-3 pb-3">
        <p className="pt-3 pb-3">
          At <strong>Science Photons</strong>, we believe that research should be accessible to everyone. Our Open Access (OA) policy allows published articles to be freely available, removing barriers to knowledge and supporting a global exchange of information. By choosing Open Access, authors can make their work more visible and impactful, allowing students, researchers, and professionals everywhere to access it without restrictions.
        </p>
        <section>
          <div
            className="p-3"
            style={{
              backgroundColor: "#FFFDF7",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.16)",
              borderRadius: "8px",
            }}
          >
            <h6 className="fw-bold">Key Benefits of Open Access at Science Photons</h6>
            <ul>
              <li><strong>Worldwide Reach:</strong> Open Access means your research is available to readers around the world, expanding its reach and potential impact.</li>
              <li><strong>Increased Citations:</strong> Studies show that Open Access articles are cited more frequently, which helps boost your work’s recognition.</li>
              <li><strong>No Paywalls:</strong> With Open Access, readers can view your articles for free, making knowledge available to anyone interested.</li>
              <li><strong>Promotes Collaboration:</strong> Open Access encourages researchers to work together, speeding up progress in science and medicine.</li>
            </ul>

            <h6 className="fw-bold mt-3">Why Choose Open Access with Science Photons?</h6>
            <ul>
              <li><strong>Clear, Fair Publishing Process:</strong> Our Open Access model is straightforward, with transparent publishing fees that are tailored to be accessible for authors.</li>
              <li><strong>Creative Commons Licensing:</strong> We publish under the CC BY license, allowing others to share and build on your work, provided they give appropriate credit. This helps expand your work’s reach while protecting your authorship.</li>
              <li><strong>High Standards in Publishing:</strong> Science Photons follows strong ethical and scientific standards, with a rigorous peer-review process that ensures only quality research is published.</li>
              <li><strong>Diverse Content Types:</strong> We publish research articles, reviews, case reports, editorials, and more, allowing for a variety of voices and topics in our journals.</li>
            </ul>

            
          </div>
        </section>

        <section className="mt-4">
          <div
            className="p-3"
            style={{
              backgroundColor: "#FFFDF7",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.16)",
              borderRadius: "8px",
            }}
          >
            <h6 className="fw-bold">Empowering Knowledge for All:
            </h6>
            <p>Choosing Open Access with Science Photons is not only about increasing your work’s visibility;
it’s about contributing to a mission of free, open knowledge. In a world where science has the
power to improve lives, we aim to make each article a valuable resource for all.</p>
<p>Join us at Science Photons in sharing research openly and helping build a world of accessible
knowledge for everyone.</p>
          </div>
        </section>
        <ContactInformation/>
      </Container>
      {/* <div
        style={{
          backgroundColor: "#095F60",
          color: "#FFFFFF",
          textAlign: "center",
        }}
        className="d-flex flex-column justify-content-center align-items-center p-4 gap-3 mt-4 mb-4"
      >
        <h2>For more details, visit our</h2> */}
        {/* <Button className="bg-white text-primary border-0">
          Contact Information
        </Button> */}
        {/* <p
          className="custom-text"
          style={{
            width: "100%",
            maxWidth: "500px",
          }}
        >
          Thank you for choosing Science Photons as your publishing platform. Together, we can illuminate the path of scientific discovery.
        </p> */}
      {/* </div> */}
      <SubmissionPage />
    </>
  );
};

export default Supportus;
