// ProtectedRoute.tsx
import React from "react";
import { Navigate } from "react-router-dom";
import { useAppContext } from "../components/context/AppContext"; // Adjust the import path as needed

// Define the props interface
interface ProtectedRouteProps {
  component: React.ComponentType; // or React.FC if you want to specify a functional component
  requiredRole: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  requiredRole,
}) => {
  const { usersData }: any = useAppContext(); // Replace with your context hook or state management

  // Check if the user has the required role
  const isAuthorized = usersData && usersData?.role?.roleName === requiredRole;

  return isAuthorized ? <Component /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
