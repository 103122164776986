import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/footer/Footer'
import ArchiveData from '../../components/journals/archiveData'

const ArchiveDataPage = () => {
    
    return (
        <div>
            <Header />
            <ArchiveData />
            <Footer />
        </div>
    )
}

export default ArchiveDataPage