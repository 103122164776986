import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROLES } from "../../app/utility/app-codes";
import Logout from "./../../assets/images/logout.png";
import Swal from "sweetalert2";
import logoImg from '../../assets/images/FINAL LOGO AAABB copy.jpg'

export const Header = () => {
  const navigation = useNavigate();
  const userdata: any = sessionStorage.getItem("loginData");
  const userRole = JSON.parse(userdata);

  // const onClickLogout = () => {
  //   navigation("/");
  //   sessionStorage.clear();
  // };
  const onClickLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        // or localStorage.clear(); if you're using localStorage

        // Redirect to home or login page
        navigation("/");
      }
    });
  };
  const handleClickUser = () => {
    navigation("/user/profile");
  };

  return (
    <div className="text-white d-flex primary-bg justify-content-between py-3">
      <div>
        <a className="navbar-brand text-white fw-bold">
          <div>
            <img
              src={logoImg}
              className="rounded"
              style={{ height: "55px", width: "100%" }}
            />
         
          </div>
        
        </a>
      </div>
      <div className="d-flex justify-content-between">
        {ROLES.User === userRole?.role?.roleName ? (
          <>
            <h5 className="mx-3 cursor-pointer">{userRole?.userName}</h5>
            <div>
              <button
                className="bg-transparent border-0 cursor-pointer"
                onClick={onClickLogout}
              >
                <img src={Logout} className="user-profile-image" />
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="text-white mx-3 cursor-pointer pt-1">
              <h5>
                {/* {userRole?.role?.roleName?.charAt(0)?.toUpperCase() +
                  userRole?.role?.roleName?.slice(1)?.toLowerCase() || "admin"} */}
                  {userRole?.userName}
              </h5>
            </div>
            <div>
              <button
                className="bg-transparent border-0 cursor-pointer text-white"
                onClick={onClickLogout}
              >
                <img src={Logout} className="user-profile-image" />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
