import React, { useEffect, useState } from "react";
import { AdminService } from "../../app/service/admin.service";
import { toast } from "react-toastify";
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  ButtonGroup,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Pagination from "./pagination";
import ThreeCirclesLoader from "../loader/index";
import SubmissionPage from "../submissionPage/SubmissionPage";
import journalBg from "../../assets/images/journal-img.png";

interface Journal {
  _id: string;
  journalTitle: string;
  journalImage: string;
  description: string;
  ISSNNumber?: string;
}

const ViewAllJournals = () => {
  const [journalData, setJournalData] = useState<Journal[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getAllJournalsData(currentPage);
  }, [currentPage]);

  const getAllJournalsData = async (page: number) => {
    setLoading(true);
    const data = {
      search: "",
      start: page,
      count: 6,
    };

    try {
      const res = await AdminService.getAllJournalsData(data);
      if (res.status === "Success") {
        if (res.data) {
          setJournalData(res.data);
          setTotalPages(res.totalPages || 1);
        }
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("An error occurred while fetching journals.");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleCardClick = (journal: Journal, name: any) => {
    if (name === "jHome") {
      const formattedTitle = journal.journalTitle
        .replace(/\s+/g, "-")
        .toLowerCase();
      navigate(`/journal-details/${formattedTitle}`, { state: { journal } });
    } else if (name === "edHome") {
      const formattedTitle = journal.journalTitle
        .replace(/\s+/g, "-")
        .toLowerCase();
      navigate(`/editor-journal-details/${formattedTitle}`, {
        state: { journal },
      });
    }
  };

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center text-center text-warning"
        style={{
          backgroundImage: `url(${journalBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "200px",
          width: "100%",
        }}
      >
        <h3 className="fw-bold text-white">SciencePhotons Journals</h3>
      </div>
      <Container className="px-lg-5 px-md-4 px-sm-3 mt-3">
        {loading ? (
          <ThreeCirclesLoader />
        ) : (
          <Row className="pb-4">
            {journalData.map((journal: Journal) => (
              <Col
                md={6}
                key={journal._id}
                className="mb-4 border-border-none"
                style={{ cursor: "pointer" }}
              >
                <Card
                  className="shadow h-100"
                  style={{ backgroundColor: "#FFFDF7" }}
                >
                  <Row noGutters className="align-items-stretch h-100">
                    {/* Image on the Left */}
                    <Col xs={12} md={4} className="d-flex">
                      <Card.Img
                        src={journal.journalImage}
                        className="w-100"
                        style={{
                          objectFit: "cover",
                          height: "100%",
                          borderBottomRightRadius: "0px",
                          borderTopRightRadius: "0px",
                        }}
                      />
                    </Col>

                    {/* Content on the Right */}
                    <Col xs={12} md={8}>
                      <Card.Body className="p-3 d-flex flex-column justify-content-between h-100">
                        {/* Read Time in the Top Right */}
                        <div className="d-flex justify-content-end mb-2">
                          <p
                            className="text-muted"
                            style={{
                              fontSize: "0.8rem",
                              margin: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            3 min read
                          </p>
                        </div>

                        {/* Title */}
                        <Card.Title
                          className="mb-2"
                          style={{
                            fontSize: "1rem",
                            fontWeight: "bold",
                            paddingRight: "20px",
                          }}
                        >
                          {journal.journalTitle}
                        </Card.Title>

                        {/* ISSN Badge */}
                        <div
                          style={{
                            backgroundColor: "#FFD700",
                            color: "#ffffff",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            fontSize: "0.9rem",
                            fontWeight: "bold",
                            width: "fit-content",
                            marginBottom: "10px",
                          }}
                        >
                          ISSN: {journal.ISSNNumber || "NA"}
                        </div>

                        {/* Description */}
                        <Card.Text
                          style={{
                            fontSize: "0.9rem",
                            color: "#555",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                journal.description.substring(0, 100) + "...",
                            }}
                          />
                        </Card.Text>

                        {/* Button Group */}
                        <ButtonGroup
                          className="w-100"
                          aria-label="Journal Links"
                          style={{
                            border: "1px solid #007bff",
                            borderRadius: "8px",
                            overflow: "hidden",
                          }}
                        >
                          <Button
                            variant="outline-primary"
                            size="sm"
                            style={{
                              borderRight: "1px solid #007bff",
                              flex: 1,
                              padding: "6px 8px",
                            }}
                            onClick={() => handleCardClick(journal, "jHome")}
                          >
                            Journal Home
                          </Button>
                          <Button
                            variant="outline-primary"
                            size="sm"
                            style={{
                              borderRight: "1px solid #007bff",
                              flex: 1,
                              padding: "6px 8px",
                            }}
                            onClick={() => handleCardClick(journal, "edHome")}
                          >
                            Editorial Board
                          </Button>
                          {/* <Button
                            variant="outline-primary"
                            size="sm"
                            style={{ flex: 1, padding: "6px 8px" }}
                          >
                            Current Issue
                          </Button> */}
                        </ButtonGroup>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Container>
      {!loading && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
      <SubmissionPage />
    </>
  );
};

export default ViewAllJournals;
