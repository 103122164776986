import React, { useEffect } from 'react'
import Header from '../../components/Header/Header'
import Aims from '../../components/journals/aims'
import Footer from '../../components/footer/Footer'

const AimsPage = () => {

  useEffect(()=>{
    scrollToTop()
  },[])

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};
  return (
    <div>
        <Header/>
        <Aims/>
        <Footer/>
    </div>
  )
}

export default AimsPage