import { DataTableCol } from "../../../components/tables/types";

export const ManuscriptDataGridCols: DataTableCol[] = [
    {
        title: 'S.no',
        control: 'serialNo',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Name',
        control: 'fullName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Email',
        control: 'email',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Article Type',
        control: 'articleType',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Received Date',
        control: 'createdAt',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Journal Name',
        control: 'journalName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Country',
        control: 'country',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Image',
        control: 'manuscriptFile',
        sortable: false,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: false,
        isDelete: true,
        canShowColumn: true
    },
];