import React from 'react'
import Header from '../../components/Header/Header'
import Aboutus from '../../components/aboutus/Aboutus'
import Footer from '../../components/footer/Footer'

const AboutUsPage = () => {
  return (
    <div>
        <Header/>
        <Aboutus/>
        <Footer/>
    </div>
  )
}

export default AboutUsPage