import React, { useState, useEffect } from "react";
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";
import { DataTable } from "../../../components/tables";
import { ManuscriptDataGridCols } from "./data-grid-cols";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import moment from "moment";
import Swal from "sweetalert2";

const ManuscriptList = () => {
  const [name, setName] = useState("");
  const [registersData, setRegistersData] = useState([]);
  const [filteredRegisters, setFilteredRegisters] = useState([]);

  const handleCancel = () => {
    setName("");
    setFilteredRegisters(registersData); // Reset the filtered data to the original list
  };

  const handleSearch = () => {
    const filteredData = registersData.filter((register: any) =>
      register.fullName.toLowerCase().includes(name.toLowerCase())
    );
    setFilteredRegisters(filteredData);
  };

  useEffect(() => {
    getRegisters();
  }, []);
  const getRegisters = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
      type: "ambassador",
    };

    AdminService.getAllManuscripts(data).then((res: any) => {
      if (res.status === "Success") {
        if (res.data) {
          const formattedData = res.data.map((item: any) => {
            return {
              ...item,
              createdAt: moment(item.createdAt).format("YYYY-MM-DD"),
            };
          });
          setRegistersData(formattedData);
          setFilteredRegisters(formattedData);
        }
      } else {
        toast.error(res.message);
      }
    });
  };

  const deletemanuscriptInfo = (manuscriptId: string) => {
    Swal.fire({
      title: "Are you sure you want to delete Online Submissions",
      text: "Once deleted, you won't be able to recover this entry!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F5BE18",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          manuscriptId: manuscriptId,
        };

        AdminService.deleteManuscript(payload).then((res: any) => {
          if (res.status === "Success") {
            toast.success(res.message);
            getRegisters(); 
          } else {
            toast.error(res.message);
          }
        });
      }
    });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <b>List of Online Submissions</b>
                </label>
                <div className="d-flex">
                  <input
                    name="User Name"
                    onChange={(e: any) => setName(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={name}
                    placeholder="Enter the User Name"
                  />
                  <button
                    className="btn btn-primary rounded px-4 py-1"
                    onClick={() => handleSearch()}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                {/* <div className="text-end">
                  <button
                    className="btn btn-success"
                    onClick={handleCreateUser}
                  >
                    Create Journals
                  </button>
                </div> */}
              </div>
            </div>
            <div className="mt-5">
              <DataTable
                tableData={filteredRegisters}
                TableCols={ManuscriptDataGridCols}
                deletemanuscriptInfo={deletemanuscriptInfo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManuscriptList;
