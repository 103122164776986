import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  BsFillGridFill,
  BsPersonFill,
  BsBook,
  BsBriefcaseFill,
  BsLaptop,
  BsAwardFill,
  BsJournalBookmark,
} from "react-icons/bs";
import { SiGooglemeet } from "react-icons/si";
import { FaBook,FaImages } from "react-icons/fa";
import { RiContactsFill } from "react-icons/ri";

export const Sidebar = () => {
  const [sidebarMenu, setSidebarMenu] = useState<any[]>([]);

  useEffect(() => {
    const loginData: any = sessionStorage.getItem("loginData");
    const data = JSON.parse(loginData);
    getSidebarMenuList(data?.role?.roleName);
  }, []);

  let getSidebarMenuList = (role: string) => {
    let list: any = [];
    if (role === "user") {
      list = [
        {
          name: "Dashboard",
          route: "/dashboard",
          icon: BsFillGridFill,
        },
        {
          name: "journals List",
          route: "/user/journalsList",
          icon: BsBook,
        },
        {
          name: "Articles in press",
          route: "/user/articlesList",
          icon: BsBriefcaseFill,
        },
        {
          name: "Editorial List",
          route: "/user/editorialList",
          icon: BsLaptop,
        },
        {
          name: "Previous Issues",
          route: "/user/previousIssues",
          icon: BsJournalBookmark,
        },
        {
          name: "Current Issues",
          route: "/user/currentIssues",
          icon: BsJournalBookmark,
        },
      ];
    } else if (role === "admin") {
      list = [
        {
          name: "Dashboard",
          route: "/dashboard",
          icon: BsFillGridFill,
        },
        {
          name: "User List",
          route: "/admin/userList",
          icon: BsPersonFill,
        },
        {
          name: "Create New Journals",
          route: "/admin/journalsList",
          icon: FaBook,
        },
        {
          name: "Manage Journals",
          route: "/admin/journalsDataList",
          icon: BsBook,
        },
        {
          name: "Online Submissions",
          route: "/admin/manuscriptList",
          icon: BsBriefcaseFill,
        },
        // {
        //   name: "Articles in press",
        //   route: "/admin/articlesList",
        //   icon: FaCalendarCheck,
        // },
        // {
        //   name: "Editorial Board",
        //   route: "/admin/editorialList",
        //   icon: BsPersonFill,
        // },
        {
          name: "Contact Info",
          route: "/admin/contactUsList",
          icon: RiContactsFill,
        },
        {
          name: "Online EB request",
          route: "/admin/editorJoiningList",
          icon: RiContactsFill,
        },
        {
          name: "Header Images List",
          route: "/admin/HeaderImagesList",
          icon: FaImages,
        },

      ];
    } else if (role === "support") {
      list = [
        {
          name: "Manage Courses",
          route: "/dashboard",
          icon: BsLaptop,
        },
        {
          name: "Certificates",
          route: "/support/certificatelist",
          icon: BsAwardFill,
        },
        {
          name: "LMS Videos",
          route: "/support/lmsvideosslist",
          icon: BsPersonFill,
        },
        {
          name: "Project",
          route: "/support/projectlist",
          icon: BsPersonFill,
        },
        {
          name: "Student CGPA",
          route: "/support/cgpalist",
          icon: BsPersonFill,
        },
        {
          name: "Career Guidances",
          route: "/support/careerGuidances",
          icon: BsPersonFill,
        },
        {
          name: "Google Meet List",
          route: "/support/meetslist",
          icon: SiGooglemeet,
        },
        {
          name: "Added SignUpList",
          route: "/support/signUpList",
          icon: BsPersonFill,
        },
      ];
    }
    setSidebarMenu(list);
  };

  return (
    <div className="primary-bg text-white">
      <div className="sidebar_a">
        <ul className="nav-list px-0 pt-4">
          {sidebarMenu.map((data: any, index: number) => (
            <NavLink
              key={index}
              to={data.route}
              className="text-decoration-none text-white sidemenu-list-item"
            >
              <li className="py-2 pe-5 text-start ps-3 py-3 sidemenu-item px-4 border-bottom border-white">
                <data.icon className="me-2" />
                &nbsp; &nbsp;&nbsp;
                {data.name}
              </li>
            </NavLink>
          ))}
        </ul>
      </div>
    </div>
  );
};
