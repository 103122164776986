import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import AllJournals from "../../components/journals/AllJournals";

const JournalsPage = () => {
  return (
    <div>
      <Header />
      <AllJournals />
      <Footer />
    </div>
  );
};

export default JournalsPage;
