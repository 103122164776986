import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink, useLocation } from "react-router-dom";
import logoImg from "../../assets/images/FINAL LOGO AAABB copy.jpg";
import { Button } from "react-bootstrap";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
        if (window.scrollY > 50) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

  const isActive = (path: any) => (location.pathname === path ? "active" : "");
  // style={{position:'fixed', width:'100%', top:'0', zIndex:'9999',}}

  return (
    <Navbar expand="lg" className={`bg-white navbar-bottom   `} >
      <Container>
        <Navbar.Brand as={NavLink} to="/home">
          <img src={logoImg} className="logo-size rounded" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
          <Nav.Link
              as={NavLink}
              to="/home"
              className={isActive("/home")}
              
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/aboutus"
              className={isActive("/aboutus")}
            >
              About Us
            </Nav.Link>
            <NavDropdown title="Guidelines" id="basic-nav-dropdown">
              <NavDropdown.Item
                as={NavLink}
                to="/author-guideliness"
                className={isActive("/author-guideliness")}
              >
                Author Guidelines
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to="/reviewer-guidelines"
                className={isActive("/reviewer-guidelines")}
              >
                Reviewer Guidelines
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to="/editor-guidelines"
                className={isActive("/editor-guidelines")}
              >
                Editor Guidelines
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to="/review-process"
                className={isActive("/review-process")}
              >
                Review Process
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to="/support-us"
                className={isActive("/support-us")}
              >
                Open Access
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              as={NavLink}
              to="/journals"
              className={isActive("/journals")}
            >
              Journals
            </Nav.Link>
            {/* <Nav.Link as={NavLink} to="/submission-page-url" className={isActive('/submission-page-url')}> */}
            <Nav.Link
              as={NavLink}
              to="/submission-page-urls"
              className={`${isActive('/submission-page-urls')} mb-0 pt-0`}  
                >
              <Button className="button-color px-3 py-3">
                Submit Manuscript
              </Button>
            </Nav.Link>

            <Nav.Link
              as={NavLink}
              to="/contact-us"
              className={isActive("/contact-us")}
              
            >
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
