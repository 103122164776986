import React, { useState, useEffect } from "react";
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";
import { DataTable } from "../../../components/tables";
import { useNavigate } from "react-router-dom";
import { JournalsDataListGridCols } from "./data-grid-cols";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const UserJournalsList = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [filteredRegisters, setFilteredRegisters] = useState([]);
  const [journalData, setJournalData] = useState<any>([]);
  const loginData: any = sessionStorage.getItem("loginData");
  const userData = JSON.parse(loginData);

  const handleCancel = () => {
    setName("");
    setFilteredRegisters(journalData);  // Resetting to the original journal data
  };

  const handleSearch = () => {
    const filteredData = journalData.filter((journal: any) =>
      journal.journalTitle.toLowerCase().includes(name.toLowerCase())  // Searching by journalTitle
    );
    setFilteredRegisters(filteredData);
  };

  useEffect(() => {
    getJournalsData();
  }, []);

  const getJournalsData = () => {
    const data = {
      journalId: userData?.journalName?._id,
    };

    AdminService.getJournalData(data).then((res) => {
      if (res.status === "Success") {
        setJournalData(res.data);
        setFilteredRegisters(res.data);  // Initialize filteredRegisters with the journal data
      } else {
        console.log("err", res.message);
      }
    });
  };

  const deleteJournalsDataInfo = (journalDataId: string) => {
    Swal.fire({
      title: "Are you sure you want to delete this journal entry?",
      text: "Once deleted, you won't be able to recover this entry!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F5BE18",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          journalDataId: journalDataId
        };
  
        AdminService.deleteJournalData(payload).then((res: any) => {
          if (res.status === "Success") {
            toast.success(res.message);
            getJournalsData();
          } else {
            toast.error(res.message);
          }
        });
      }
    });
  };

  const handleEditUserJournal = (data: any) => {
    navigate("/user/editjournal", { state: data });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <b>Journals List Names</b>
                </label>
                <div className="d-flex">
                  <input
                    name="Journal Name"
                    onChange={(e: any) => setName(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={name}
                    placeholder="Enter the Journal Name"
                  />
                  <button
                    className="btn btn-primary rounded px-4 py-1"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <DataTable
                tableData={filteredRegisters}  // Use filtered data
                TableCols={JournalsDataListGridCols}
                deleteJournalsDataInfo={deleteJournalsDataInfo}
                handleEditUserJournal={handleEditUserJournal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserJournalsList;
