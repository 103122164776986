import React, { useState, useEffect } from "react";
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import { Button, Card, Col, Row } from "react-bootstrap";
import moment from "moment";

const CurrentIssuesList = () => {
  const [name, setName] = useState("");
  const [articleData, setArticleData] = useState<any>([]);
  const [filteredEditorData, setFilteredEditorData] = useState<any>([]);
  const navigate = useNavigate();
  const loginData: any = sessionStorage.getItem("loginData");
  const userData = JSON.parse(loginData);

  const handleCancel = () => {
    setName("");
  };

  const handleSearch = () => {
    if (name.trim() === "") {
      setFilteredEditorData(articleData);
      return;
    }
    const filteredData = articleData.filter((editor: any) =>
      editor.editorName.toLowerCase().includes(name.toLowerCase())
    );
    setFilteredEditorData(filteredData);
  };



  useEffect(() => {
    getArticleData();
  }, []);

  const getArticleData = () => {
    const data = {
      articleId: userData?.journalName?._id,
      currentIssues: true,
      previousIssues: false,
    };

    AdminService.getArticle(data).then((res) => {
      if (res.status === "Success") {
        setArticleData(res.data);
      } else {
        console.log("err", res.message);
      }
    });
  };


  const EditArticles = (data: any) => {
    let isEditable=true
    navigate("/user/editarticles", { state: { data, isEditable } });
  };

  const PreviousIssuesList=(data:any)=>{
    const payload = {
      articleId: data._id,
      typeSelection:"previousIssues"
    };
    AdminService.updateArticleIssues(payload).then((res: any) => {
      if (res.status === "Success") {
        setArticleData((prevData: any) => prevData.filter((item: any) => item._id !== data._id));
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  }

  const articleInPress=(data:any)=>{
    const payload = {
      articleId: data._id,
      typeSelection:"articleInPress"
    };
    AdminService.updateArticleIssues(payload).then((res: any) => {
      if (res.status === "Success") {
        setArticleData((prevData: any) => prevData.filter((item: any) => item._id !== data._id));
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  }
  

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-12 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <b>Current Issues List</b>
                </label>
                <div className="d-flex">
                  <input
                    name="User Name"
                    onChange={(e: any) => setName(e.target.value)}
                    className="form-control mt-1 me-2 w-25"
                    value={name}
                    placeholder="Enter the Author Name"
                  />
                  <button
                    className="btn btn-primary rounded px-4 py-1"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              {articleData?.length > 0 ? (
                articleData.map((item: any, index: number) => (
                  <div className="col-md-12 mb-3" key={index}>
                    <Card>
                      <Card.Header as="h5">{item.description || "-"}</Card.Header>
                      <Row>
                        <Col md={8}>
                          <Card.Body>
                            <Card.Title>{item?.title}</Card.Title>
                            <Card.Text>
                              Author(s): {item?.authorNames || "N/A"}
                            </Card.Text>
                            <Card.Text>
                            Published on: {item?.publishDate ? moment(item.publishDate).format("MMMM Do, YYYY") : "N/A"}
                            </Card.Text>
                          </Card.Body>
                        </Col>
                        <Col
                          md={4}
                          className="d-flex flex-column align-items-start p-3"
                        >
                          <h6>{item?.publishDate ? moment(item.publishDate).format("MMMM Do, YYYY") : "N/A"}</h6>
                          <Button variant="outline-primary" className="mb-2" onClick={()=>PreviousIssuesList(item)}>
                            Move To Previous Issue
                          </Button>
                          <Button variant="outline-secondary" className="mb-2" onClick={()=>articleInPress(item)}>
                            Move To Article in Press
                          </Button>
                          <Button variant="outline-success"  onClick={() => EditArticles(item)}>
                            Edit Article
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                ))
              ) : (
                <p>No data available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentIssuesList;
