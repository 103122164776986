import axios from 'axios';
import { toast } from 'react-toastify';

const API = axios.create({
    baseURL: "https://api.sciencephotons.com/api/v1"
});

// Intercept all requests and add the Authorization header
API.interceptors.request.use(
    (config: any) => {
        const token = sessionStorage.getItem('token');


        // Set the Authorization header if token exists
        config.headers.Authorization = `Bearer ${token}`;

        // Set appropriate Content-Type
        const contentType = config.headers["Content-Type"];
        if (contentType === "multipart/form-data") {
            config.headers["Content-Type"] = "multipart/form-data";
        } else {
            config.headers["Content-Type"] = "application/json";
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Intercept all responses and handle 401 errors or success data
API.interceptors.response.use(
    (response: any) => {
        if (response.data.data) {
            return response.data;
        } else {
            return response.data ? response.data : {};
        }
    },
    (error: any) => {

        if (error?.response?.status === 401) {
            window.location.href = '/';
            toast.error("Session expired, redirecting to login...");
        }

        return error.response && error.response.data ? error.response.data : {};
    }
);

export default API;
