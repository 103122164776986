import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation, NavLink } from "react-router-dom";
import logoImg from "../../assets/images/logo.jpg";
import EditorJournalsList from "./editorJournalsList";
import { AdminService } from "../../app/service/admin.service";
import moment from "moment";
import SubmissionPage from "../submissionPage/SubmissionPage";
import { FaLockOpen } from "react-icons/fa";

const SpecialIssues = () => {
  const { state } = useLocation();
  console.log("state", state?.journal);

  const [articleData, setArticleData] = useState([]);
  const [jData, setJData] = useState<any>([]);

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const createSlug = (title: any) => {
    return title
      ?.toLowerCase() // Convert to lowercase
      ?.replace(/[^a-z0-9\s-]/g, "") // Remove any special characters
      ?.trim() // Remove leading and trailing whitespace
      ?.replace(/\s+/g, "-"); // Replace spaces with hyphens
  };

  const items = [
    {
      label: "Journal Home",
      path: `/journal-details/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    {
      label: "Aim and Scope",
      path: `/journalsAims/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    {
      label: "Editorial Board",
      path: `/editor-journal-details/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    {
      label: "Author Guidelines",
      path: `/author-guidelines/${createSlug(jData[0]?.journalTitle)}`,
    },
    // {
    //   label: "Special Issues",
    //   path: `/special-issues/${createSlug(jData[0]?.journalTitle)}`,
    //   state: state,
    // },
    {
      label: "Article In Press",
      path: `/article-in-press/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    {
      label: "Current Issue",
      path: `/current-issue/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    {
      label: "Archives",
      path: `/archives/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    // {
    //   label: "Open Access Policy",
    //   path: `/open-access/${createSlug(jData[0]?.journalTitle)}`,
    // },
    {
      label: "Submit Manuscript",
      path: `/submission-page-url/${createSlug(jData[0]?.journalTitle)}`,
    },
  ];

  const topics = [
    "Neonatal care",
    "Pediatric infectious diseases",
    "Pediatric oncology",
    "Pediatric cardiology",
    "Pediatric endocrinology",
    "Pediatric neurology",
    "Pediatric surgery",
    "Pediatric respiratory health",
    "Growth and developmental disorders",
    "Adolescent health",
    "Pediatric mental health",
    "Pediatric nutrition and obesity",
    "Pediatric emergency care",
    "Preventive pediatrics and vaccinations",
    "Pediatric immunology and allergies",
    "Pediatric genetics",
    "Pediatric dermatology",
    "Pediatric nephrology",
    "Pediatric rheumatology",
    "Pediatric critical care",
    "Child and adolescent healthcare policy and practice",
  ];

  useEffect(() => {
    getArticleData();
    getJournalData();
  }, []);

  const getArticleData = () => {
    const data = {
      articleId:
        state?.journal?.journalNameId ||
        state?.journalId ||
        state?.articlejournalName,
      currentIssues: true,
      previousIssues: false,
    };

    AdminService.getArticle(data).then((res) => {
      if (res.status === "Success" && res.data) {
        setArticleData(res.data);
      } else {
        console.log("err", res.message);
      }
    });
  };

  const getJournalData = () => {
    const data = {
      journalId:
        state?.journal?.journalNameId ||
        state?.journalId ||
        state?.articlejournalName,
    };
    AdminService.getJournalData(data).then((res) => {
      if (res.status === "Success") {
        setJData(res.data);
      } else {
        console.log("err", res.message);
      }
    });
  };

  return (
    <div className="pb-3">
      <style>
        {`
          .scrollable-top::-webkit-scrollbar {
            width: 3px;
          }
          .scrollable-top::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 7px;
          }
          .scrollable-top::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 7px;
          }
          .scrollable-top::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        `}
      </style>
      <div
        className="bg-dark py-4 px-4"
        style={{
          backgroundImage: `url(${jData[0]?.journalHeaderImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h4 className="text-white text-center">
          <b>{jData[0]?.journalTitle}</b>
        </h4>
        <h4 className="text-center text-white">
          <b>ISSN: {jData[0]?.ISSNNumber}</b>
        </h4>
      </div>
      <Container>
        <Row className="mt-3">
          <Col md={3}>
            <div>
              <img
                src={`${jData[0]?.journalImage}`}
                alt="Logo"
                className="mb-3 rounded"
                style={{width:'212px', height:'281px'}}
              />
            </div>
            <div className="mb-3">
              <div
                className="scrollable-top border-2 border-black"
                style={{ maxHeight: "400px", overflowY: "auto", maxWidth:'212px', width:'100%' }}
              >
                <h5 className="text-left">
                  <b>Journal Topics</b>
                </h5>
                <ul className="list-styled">
                  {topics.map((topic: any, index: any) => (
                    <li key={index} className="py-1">
                      {topic}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>
          <Col md={6} className="text-center">
            <h4 className="text-black text-center">Special Issues</h4>
            <b className="text-center">Coming soon!</b>
          </Col>
          <Col md={3}>
            <div
              className="scrollable-top pt-2 pb-2 ps-4 pe-4 h-100"
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                border: "none",
              }}
            >
              <h5 className="fw-bold">Index</h5>
              {items.map((item, index) => (
                //       <Link
                //         to={item.path}
                //         state={item.state} // Pass the entire state
                //         className="{({ isActive }) =>
                //   `text-decoration-none ${isActive ? 'text-primary fw-bold' : 'text-black'}`
                // } text-decoration-none text-black"
                //         key={index}
                //       >
                //         <div className="">
                //           <p>{item.label}</p>
                //         </div>
                //       </Link>
                <NavLink
                  to={item.path}
                  state={item.state} // Pass the entire state
                  style={({ isActive }) => ({
                    color: isActive ? "#FFB901" : "black",
                    borderLeft: isActive ? "4px solid yellow" : "none",
                    paddingLeft: isActive ? "8px" : "12px", // Adjust padding to align text
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                  })}
                  key={index}
                >
                  <div>
                    <p className="mt-2">{item.label}</p>
                  </div>
                </NavLink>
              ))}
            </div>
          </Col>
        </Row>
        <br />
      </Container>
    </div>
  );
};

export default SpecialIssues;
