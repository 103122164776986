export const articleTypes = [
  "Research Article",
  "Review Article",
  "Mini Review Article",
  "Editorial",
  "Case Report",
  "Clinical Image",
  "Short Article",
  "Book Review",
  "Short Communication",
  "Letter to Editor",
  "Commentary",
  "Conference Proceeding",
  "Rapid Communication",
  "Special Issue Article",
  "Annual Meeting Abstract",
  "Meeting Report",
  "Proceedings",
  "Expert Review"
];

export const accessType=[
  "Subscription",
  "Open Access"
]

export const selectStatusoftheArticle=[
  "Complete",
  "Incomplete"
]

export const title = [
  "Dr",
  "Mr",
  "Mrs",
  "Miss",
  "Ms",
  "Professor"
]

export const roles = [
  {
    id: 1,
    name: 'User',
    value: 'user'
  },
  // {
  //   id: 2,
  //   name: 'Support',
  //   value: 'support'
  // },
  // {
  //   id: 3,
  //   name: 'BDI',
  //   value: 'bdi'
  // },
  // {
  //   id: 4,
  //   name: 'Operations',
  //   value: 'operations'
  // },
  {
    id: 5,
    name: 'Admin',
    value: 'admin'
  },
]
export const countryList = [
  { id: 1, name: 'Afghanistan', code: 'AF' },
  { id: 2, name: 'Albania', code: 'AL' },
  { id: 3, name: 'Algeria', code: 'DZ' },
  { id: 4, name: 'Andorra', code: 'AD' },
  { id: 5, name: 'Angola', code: 'AO' },
  { id: 6, name: 'Argentina', code: 'AR' },
  { id: 7, name: 'Armenia', code: 'AM' },
  { id: 8, name: 'Australia', code: 'AU' },
  { id: 9, name: 'Austria', code: 'AT' },
  { id: 10, name: 'Azerbaijan', code: 'AZ' },
  { id: 11, name: 'Bahamas', code: 'BS' },
  { id: 12, name: 'Bahrain', code: 'BH' },
  { id: 13, name: 'Bangladesh', code: 'BD' },
  { id: 14, name: 'Barbados', code: 'BB' },
  { id: 15, name: 'Belarus', code: 'BY' },
  { id: 16, name: 'Belgium', code: 'BE' },
  { id: 17, name: 'Belize', code: 'BZ' },
  { id: 18, name: 'Benin', code: 'BJ' },
  { id: 19, name: 'Bhutan', code: 'BT' },
  { id: 20, name: 'Bolivia', code: 'BO' },
  { id: 21, name: 'Bosnia and Herzegovina', code: 'BA' },
  { id: 22, name: 'Botswana', code: 'BW' },
  { id: 23, name: 'Brazil', code: 'BR' },
  { id: 24, name: 'Brunei', code: 'BN' },
  { id: 25, name: 'Bulgaria', code: 'BG' },
  { id: 26, name: 'Burkina Faso', code: 'BF' },
  { id: 27, name: 'Burundi', code: 'BI' },
  { id: 28, name: 'Cabo Verde', code: 'CV' },
  { id: 29, name: 'Cambodia', code: 'KH' },
  { id: 30, name: 'Cameroon', code: 'CM' },
  { id: 31, name: 'Canada', code: 'CA' },
  { id: 32, name: 'Central African Republic', code: 'CF' },
  { id: 33, name: 'Chad', code: 'TD' },
  { id: 34, name: 'Chile', code: 'CL' },
  { id: 35, name: 'China', code: 'CN' },
  { id: 36, name: 'Colombia', code: 'CO' },
  { id: 37, name: 'Comoros', code: 'KM' },
  { id: 38, name: 'Congo (Congo-Brazzaville)', code: 'CG' },
  { id: 39, name: 'Costa Rica', code: 'CR' },
  { id: 40, name: 'Croatia', code: 'HR' },
  { id: 41, name: 'Cuba', code: 'CU' },
  { id: 42, name: 'Cyprus', code: 'CY' },
  { id: 43, name: 'Czech Republic', code: 'CZ' },
  { id: 44, name: 'Denmark', code: 'DK' },
  { id: 45, name: 'Djibouti', code: 'DJ' },
  { id: 46, name: 'Dominica', code: 'DM' },
  { id: 47, name: 'Dominican Republic', code: 'DO' },
  { id: 48, name: 'Ecuador', code: 'EC' },
  { id: 49, name: 'Egypt', code: 'EG' },
  { id: 50, name: 'El Salvador', code: 'SV' },
  { id: 51, name: 'Equatorial Guinea', code: 'GQ' },
  { id: 52, name: 'Eritrea', code: 'ER' },
  { id: 53, name: 'Estonia', code: 'EE' },
  { id: 54, name: 'Eswatini', code: 'SZ' },
  { id: 55, name: 'Ethiopia', code: 'ET' },
  { id: 56, name: 'Fiji', code: 'FJ' },
  { id: 57, name: 'Finland', code: 'FI' },
  { id: 58, name: 'France', code: 'FR' },
  { id: 59, name: 'Gabon', code: 'GA' },
  { id: 60, name: 'Gambia', code: 'GM' },
  { id: 61, name: 'Georgia', code: 'GE' },
  { id: 62, name: 'Germany', code: 'DE' },
  { id: 63, name: 'Ghana', code: 'GH' },
  { id: 64, name: 'Greece', code: 'GR' },
  { id: 65, name: 'Grenada', code: 'GD' },
  { id: 66, name: 'Guatemala', code: 'GT' },
  { id: 67, name: 'Guinea', code: 'GN' },
  { id: 68, name: 'Guinea-Bissau', code: 'GW' },
  { id: 69, name: 'Guyana', code: 'GY' },
  { id: 70, name: 'Haiti', code: 'HT' },
  { id: 71, name: 'Honduras', code: 'HN' },
  { id: 72, name: 'Hungary', code: 'HU' },
  { id: 73, name: 'Iceland', code: 'IS' },
  { id: 74, name: 'India', code: 'IN' },
  { id: 75, name: 'Indonesia', code: 'ID' },
  { id: 76, name: 'Iran', code: 'IR' },
  { id: 77, name: 'Iraq', code: 'IQ' },
  { id: 78, name: 'Ireland', code: 'IE' },
  { id: 79, name: 'Israel', code: 'IL' },
  { id: 80, name: 'Italy', code: 'IT' },
  { id: 81, name: 'Jamaica', code: 'JM' },
  { id: 82, name: 'Japan', code: 'JP' },
  { id: 83, name: 'Jordan', code: 'JO' },
  { id: 84, name: 'Kazakhstan', code: 'KZ' },
  { id: 85, name: 'Kenya', code: 'KE' },
  { id: 86, name: 'Kiribati', code: 'KI' },
  { id: 87, name: 'Kuwait', code: 'KW' },
  { id: 88, name: 'Kyrgyzstan', code: 'KG' },
  { id: 89, name: 'Laos', code: 'LA' },
  { id: 90, name: 'Latvia', code: 'LV' },
  { id: 91, name: 'Lebanon', code: 'LB' },
  { id: 92, name: 'Lesotho', code: 'LS' },
  { id: 93, name: 'Liberia', code: 'LR' },
  { id: 94, name: 'Libya', code: 'LY' },
  { id: 95, name: 'Liechtenstein', code: 'LI' },
  { id: 96, name: 'Lithuania', code: 'LT' },
  { id: 97, name: 'Luxembourg', code: 'LU' },
  { id: 98, name: 'Madagascar', code: 'MG' },
  { id: 99, name: 'Malawi', code: 'MW' },
  { id: 100, name: 'Malaysia', code: 'MY' },
  { id: 101, name: 'Maldives', code: 'MV' },
  { id: 102, name: 'Mali', code: 'ML' },
  { id: 103, name: 'Malta', code: 'MT' },
  { id: 104, name: 'Marshall Islands', code: 'MH' },
  { id: 105, name: 'Mauritania', code: 'MR' },
  { id: 106, name: 'Mauritius', code: 'MU' },
  { id: 107, name: 'Mexico', code: 'MX' },
  { id: 108, name: 'Micronesia', code: 'FM' },
  { id: 109, name: 'Moldova', code: 'MD' },
  { id: 110, name: 'Monaco', code: 'MC' },
  { id: 111, name: 'Mongolia', code: 'MN' },
  { id: 112, name: 'Montenegro', code: 'ME' },
  { id: 113, name: 'Morocco', code: 'MA' },
  { id: 114, name: 'Mozambique', code: 'MZ' },
  { id: 115, name: 'Myanmar', code: 'MM' },
  { id: 116, name: 'Namibia', code: 'NA' },
  { id: 117, name: 'Nauru', code: 'NR' },
  { id: 118, name: 'Nepal', code: 'NP' },
  { id: 119, name: 'Netherlands', code: 'NL' },
  { id: 120, name: 'New Zealand', code: 'NZ' },
  { id: 121, name: 'Nicaragua', code: 'NI' },
  { id: 122, name: 'Niger', code: 'NE' },
  { id: 123, name: 'Nigeria', code: 'NG' },
  { id: 124, name: 'North Korea', code: 'KP' },
  { id: 125, name: 'North Macedonia', code: 'MK' },
  { id: 126, name: 'Norway', code: 'NO' },
  { id: 127, name: 'Oman', code: 'OM' },
  { id: 128, name: 'Pakistan', code: 'PK' },
  { id: 129, name: 'Palau', code: 'PW' },
  { id: 130, name: 'Palestine', code: 'PS' },
  { id: 131, name: 'Panama', code: 'PA' },
  { id: 132, name: 'Papua New Guinea', code: 'PG' },
  { id: 133, name: 'Paraguay', code: 'PY' },
  { id: 134, name: 'Peru', code: 'PE' },
  { id: 135, name: 'Philippines', code: 'PH' },
  { id: 136, name: 'Poland', code: 'PL' },
  { id: 137, name: 'Portugal', code: 'PT' },
  { id: 138, name: 'Qatar', code: 'QA' },
  { id: 139, name: 'Romania', code: 'RO' },
  { id: 140, name: 'Russia', code: 'RU' },
  { id: 141, name: 'Rwanda', code: 'RW' },
  { id: 142, name: 'Saint Kitts and Nevis', code: 'KN' },
  { id: 143, name: 'Saint Lucia', code: 'LC' },
  { id: 144, name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { id: 145, name: 'Samoa', code: 'WS' },
  { id: 146, name: 'San Marino', code: 'SM' },
  { id: 147, name: 'Sao Tome and Principe', code: 'ST' },
  { id: 148, name: 'Saudi Arabia', code: 'SA' },
  { id: 149, name: 'Senegal', code: 'SN' },
  { id: 150, name: 'Serbia', code: 'RS' },
  { id: 151, name: 'Seychelles', code: 'SC' },
  { id: 152, name: 'Sierra Leone', code: 'SL' },
  { id: 153, name: 'Singapore', code: 'SG' },
  { id: 154, name: 'Slovakia', code: 'SK' },
  { id: 155, name: 'Slovenia', code: 'SI' },
  { id: 156, name: 'Solomon Islands', code: 'SB' },
  { id: 157, name: 'Somalia', code: 'SO' },
  { id: 158, name: 'South Africa', code: 'ZA' },
  { id: 159, name: 'South Korea', code: 'KR' },
  { id: 160, name: 'South Sudan', code: 'SS' },
  { id: 161, name: 'Spain', code: 'ES' },
  { id: 162, name: 'Sri Lanka', code: 'LK' },
  { id: 163, name: 'Sudan', code: 'SD' },
  { id: 164, name: 'Suriname', code: 'SR' },
  { id: 165, name: 'Sweden', code: 'SE' },
  { id: 166, name: 'Switzerland', code: 'CH' },
  { id: 167, name: 'Syria', code: 'SY' },
  { id: 168, name: 'Taiwan', code: 'TW' },
  { id: 169, name: 'Tajikistan', code: 'TJ' },
  { id: 170, name: 'Tanzania', code: 'TZ' },
  { id: 171, name: 'Thailand', code: 'TH' },
  { id: 172, name: 'Timor-Leste', code: 'TL' },
  { id: 173, name: 'Togo', code: 'TG' },
  { id: 174, name: 'Tonga', code: 'TO' },
  { id: 175, name: 'Trinidad and Tobago', code: 'TT' },
  { id: 176, name: 'Tunisia', code: 'TN' },
  { id: 177, name: 'Turkey', code: 'TR' },
  { id: 178, name: 'Turkmenistan', code: 'TM' },
  { id: 179, name: 'Tuvalu', code: 'TV' },
  { id: 180, name: 'Uganda', code: 'UG' },
  { id: 181, name: 'Ukraine', code: 'UA' },
  { id: 182, name: 'United Arab Emirates', code: 'AE' },
  { id: 183, name: 'United Kingdom', code: 'GB' },
  { id: 184, name: 'United States', code: 'US' },
  { id: 185, name: 'Uruguay', code: 'UY' },
  { id: 186, name: 'Uzbekistan', code: 'UZ' },
  { id: 187, name: 'Vanuatu', code: 'VU' },
  { id: 188, name: 'Vatican City', code: 'VA' },
  { id: 189, name: 'Venezuela', code: 'VE' },
  { id: 190, name: 'Vietnam', code: 'VN' },
  { id: 191, name: 'Yemen', code: 'YE' },
  { id: 192, name: 'Zambia', code: 'ZM' },
  { id: 193, name: 'Zimbabwe', code: 'ZW' }
];

