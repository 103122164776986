import React, { useState, useEffect } from "react";
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";
import { DataTable } from "../../../components/tables";
import { useNavigate } from "react-router-dom";
import { EditorsDataGridCols } from "./data-grid-cols";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const UserEditorialBoardList = () => {
  const [name, setName] = useState("");
  const [editorData, setEditorData] = useState<any>([]);
  const [filteredEditorData, setFilteredEditorData] = useState<any>([]);
  const navigate = useNavigate();
  const loginData: any = sessionStorage.getItem("loginData");
  const userData = JSON.parse(loginData);

  const handleCancel = () => {
    setName("");
    setFilteredEditorData(editorData); // Reset to the original data
  };

  const handleSearch = () => {
    if (name.trim() === "") {
      setFilteredEditorData(editorData);
      return;
    }
    const filteredData = editorData.filter((editor: any) =>
      editor.editorName.toLowerCase().includes(name.toLowerCase())
    );
    setFilteredEditorData(filteredData);
  };

  const handleCreateUser = () => {
    navigate("/user/createeditorialboard");
  };

  useEffect(() => {
    getEditorData();
  }, []);

  const getEditorData = () => {
    const data = {
      editorId: userData?.journalName?._id,
    };
    AdminService.getEditor(data).then((res: any) => {
      if (res.status === "Success") {
        setEditorData(res.data);
        setFilteredEditorData(res.data); // Set the initial filtered data
      } else {
        console.log("err", res.message);
      }
    });
  };

  const deleteEditorListInfo = (editorId: string) => {
    Swal.fire({
      title: "Are you sure you want to delete Editorialboard Info",
      text: "Once deleted, you won't be able to recover this entry!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F5BE18",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = { editorId: editorId };

        AdminService.deleteEditor(payload).then((res: any) => {
          if (res.status === "Success") {
            toast.success(res.message);
            getEditorData();
          } else {
            toast.error(res.message);
          }
        });
      }
    });
  };

  const handleEditorialBoard = (data: any) => {
    navigate("/user/editorialboard", { state: data });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <b>Editor List</b>
                </label>
                <div className="d-flex">
                  <input
                    name="User Name"
                    onChange={(e: any) => setName(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={name}
                    placeholder="Enter the Editor Name"
                  />
                  <button
                    className="btn btn-primary rounded px-4 py-1"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="text-end">
                  <button
                    className="btn btn-success"
                    onClick={handleCreateUser}
                  >
                    Create Editor Board
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <DataTable
                tableData={filteredEditorData} // Use filtered data
                TableCols={EditorsDataGridCols}
                deleteEditorListInfo={deleteEditorListInfo}
                handleEditorialBoard={handleEditorialBoard}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEditorialBoardList;
