import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import ReviewProcess from "../../components/reviewProcess/ReviewProcess";
import Footer from "../../components/footer/Footer";

const ReviewProcessPage = () => {

  useEffect(()=>{
    scrollToTop()
  },[])

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};
  return (
    <div>
      <Header />
      <ReviewProcess />
      <Footer />
    </div>
  );
};

export default ReviewProcessPage;
