import { DataTableCol } from "../../../components/tables/types";

export const HeaderDataGridCols: DataTableCol[] = [
    {
        title: 'S.no',
        control: 'serialNo',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Image',
        control: 'headerImage',
        sortable: false,
        canShowColumn: true
    },
   
    {
        title: 'Description',
        control: 'description',
        sortable: true,
        canShowColumn: true
    },
  
   
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        canShowColumn: true
    },
];