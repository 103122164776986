import React,{useEffect} from 'react'
import Header from '../../components/Header/Header'
import Manuscript from '../../components/manuscript/Manuscript'
import Footer from '../../components/footer/Footer'

const ManuscriptDatapage = () => {

  useEffect(()=>{
    scrollToTop()
  },[])

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};
  return (
    <>
    <Header/>
    <Manuscript/>
    <Footer/>
    </>
  )
}

export default ManuscriptDatapage