import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import Select from "react-select";
import { articleTypes, countryList, title } from "../../config";
import { AdminService } from "../../app/service/admin.service";
import { toast } from "react-toastify";
import { FaUpload } from "react-icons/fa"; // Icon for file upload
import contactUs from "../../assets/images/contactus-bg.png";

const Manuscript = () => {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState<any>({
    title: "",
    fullName: "",
    email: "",
    country: "",
    articleType: "",
    manuscriptTitle: "",
    postalAddress: "",
    abstract: "",
    journalName: "",
    manuscriptFiles: [],
  });
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    getJournalNames();
  }, []);

  const getJournalNames = async () => {
    try {
      const response = await AdminService.allJournalNames("");
      setData(response.data);
    } catch (error) {
      console.error("Error fetching journal names:", error);
    }
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [id]: "",
    }));
  };

  const handleFileChange = (e: any) => {
    const newFiles = Array.from(e.target.files);
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      manuscriptFiles: [...(prevFormData.manuscriptFiles || []), ...newFiles],
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      manuscriptFiles: "",
    }));
  };

  const handleSelectChange = (selectedOption: any, { name }: any) => {
    setFormData({ ...formData, [name]: selectedOption.value });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!formData.title) newErrors.title = "Title is required.";
    if (!formData.fullName) newErrors.fullName = "Full name is required.";
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!formData.country) newErrors.country = "Country is required.";
    if (!formData.articleType) newErrors.articleType = "Article type is required.";
    if (!formData.manuscriptTitle) newErrors.manuscriptTitle = "Manuscript title is required.";
    if (!formData.postalAddress) newErrors.postalAddress = "Postal address is required.";
    if (!formData.abstract) newErrors.abstract = "Abstract is required.";
    if (!formData.journalName) newErrors.journalName = "Journal name is required.";
    if (!formData.manuscriptFiles.length) newErrors.manuscriptFiles = "At least one manuscript file is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e?: any) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("title", formData.title);
      formDataToSend.append("fullName", formData.fullName);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("country", formData.country);
      formDataToSend.append("articleType", formData.articleType);
      formDataToSend.append("manuscriptTitle", formData.manuscriptTitle);
      formDataToSend.append("postalAddress", formData.postalAddress);
      formDataToSend.append("abstract", formData.abstract);
      formDataToSend.append("journalName", formData.journalName);
      formData.manuscriptFiles.forEach((file: File) => {
        formDataToSend.append("manuscriptFiles", file);
      });

      const res = await AdminService.createManuscript(formDataToSend);

      if (res.status === "Fail") {
        toast.error(res.error);
      } else {
        setFormData({
          title: "",
          fullName: "",
          email: "",
          country: "",
          articleType: "",
          manuscriptTitle: "",
          postalAddress: "",
          abstract: "",
          journalName: "",
          manuscriptFiles: [],
        });
        toast.success(res.message);
      }
    } catch (error) {
      toast.error("An error occurred while submitting the manuscript");
      console.error("Error submitting manuscript:", error);
    }
  };

  const titleOptions = title?.map((type) => ({ value: type, label: type }));
  const countryOptions = countryList?.map((type) => ({ value: type.name, label: type.name }));
  const articleTypeOptions = articleTypes?.map((type) => ({ value: type, label: type }));
  const journalOptions = data?.map((type: any) => ({ value: type.journalName, label: type.journalName }));

  return (
    <>
    {/* <div className="d-flex align-items-center justify-content-center text-center text-warning my-4">
  <h4 className="fw-bold text-black">
    Submit Manuscript
  </h4>
</div> */}
 <div
        className="d-flex align-items-center justify-content-center text-center text-warning"
        style={{
          backgroundImage: `url(${contactUs})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "200px",
          width: "100%",
        }}
      >
        <h1 className="display-5 fw-bold text-white">Submit Manuscript</h1>
      </div>
      <Container className=" mb-4 px-2 px-md-4 d-flex justify-content-center mt-4">
        <div
          className="p-3 p-md-4 p-lg-5"
          style={{
            width: "100%",
            maxWidth: "85vw", // 85% of the viewport width for better mobile responsiveness
            backgroundColor: "#fafafa",
            borderRadius: "12px", // Slightly reduced border radius for a tighter look
            border: "1px solid #9B9B9B",
          }}
        >
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label className="side-font">Title</Form.Label>
              <Select
                options={titleOptions}
                name="title"
                placeholder="Select Title"
                onChange={handleSelectChange}
              />
              {errors.title && <div className="text-danger">{errors.title}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="fullName">
              <Form.Label className="side-font">Full Name</Form.Label>
              <Form.Control type="text" placeholder="Enter full name" onChange={handleChange} />
              {errors.fullName && <div className="text-danger">{errors.fullName}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
              <Form.Label className="side-font">E-Mail</Form.Label>
              <Form.Control type="email" placeholder="Enter E-Mail" onChange={handleChange} />
              {errors.email && <div className="text-danger">{errors.email}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="country">
              <Form.Label className="side-font">Country</Form.Label>
              <Select
                options={countryOptions}
                name="country"
                placeholder="Select Country"
                onChange={handleSelectChange}
              />
              {errors.country && <div className="text-danger">{errors.country}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="articleType">
              <Form.Label className="side-font">Article Type</Form.Label>
              <Select
                options={articleTypeOptions}
                name="articleType"
                placeholder="Select Article Type"
                onChange={handleSelectChange}
              />
              {errors.articleType && <div className="text-danger">{errors.articleType}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="manuscriptTitle">
              <Form.Label className="side-font">Manuscript Title</Form.Label>
              <Form.Control type="text" placeholder="Enter Manuscript Title" onChange={handleChange} />
              {errors.manuscriptTitle && <div className="text-danger">{errors.manuscriptTitle}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="postalAddress">
              <Form.Label className="side-font">Postal Address</Form.Label>
              <Form.Control type="text" placeholder="Enter Postal Address" onChange={handleChange} />
              {errors.postalAddress && <div className="text-danger">{errors.postalAddress}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="abstract">
              <Form.Label className="side-font">Abstract</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Enter Abstract" onChange={handleChange} />
              {errors.abstract && <div className="text-danger">{errors.abstract}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="journalName">
              <Form.Label className="side-font">Select Journal Name</Form.Label>
              <Select
                options={journalOptions}
                name="journalName"
                placeholder="Select Journal Name"
                onChange={handleSelectChange}
              />
              {errors.journalName && <div className="text-danger">{errors.journalName}</div>}
            </Form.Group>

            {/* Upload Section Styled as per Design */}
            <Form.Group className="mb-4 text-center" controlId="manuscriptFile">
              <div className="d-flex justify-content-center">
                <Button
                  variant="outline-secondary"
                  className="d-flex align-items-center"
                  style={{
                    borderRadius: "8px",
                    padding: "10px 20px",
                    borderColor: "#ddd",
                  }}
                  as="label"
                >
                  <FaUpload className="me-2" style={{ fontSize: "1.2rem" }} />
                  Upload File
                  <Form.Control
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </Button>
              </div>
              <small className="text-muted d-block mt-2">(Upload in jpeg, mp4 formats only)</small>
              {errors.manuscriptFiles && <div className="text-danger">{errors.manuscriptFiles}</div>}
            </Form.Group>

            <div className="d-flex justify-content-center">
              <Button
                type="submit"
                className="mt-3"
                style={{
                  backgroundColor: "#00796b",
                  borderColor: "#00796b",
                  padding: "10px 40px",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "#fff",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                Submit Manuscript
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default Manuscript;