import React from 'react';
import { Button } from 'react-bootstrap';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const handleClick = (page: number) => {
    if (page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    const maxPagesToShow = 5;
    const showEllipsis = totalPages > maxPagesToShow;

    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, currentPage + 1);

    if (currentPage === 1) {
      endPage = Math.min(totalPages, currentPage + 2);
    } else if (currentPage === totalPages) {
      startPage = Math.max(1, totalPages - 2);
    }

    // Start Page
    if (startPage > 1) {
      pages.push(
        <Button
          key={1}
          variant="outline-dark"
          onClick={() => handleClick(1)}
          className="mx-1"
          style={{
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            fontSize: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          1
        </Button>
      );
      if (showEllipsis && startPage > 2) {
        pages.push(
          <span
            key="start-ellipsis"
            className="mx-1"
            style={{
              fontSize: "1.2rem",
              lineHeight: "40px",
              display: "inline-block",
              width: "40px",
              height: "40px",
              textAlign: "center",
            }}
          >
            ...
          </span>
        );
      }
    }

    // Middle pages
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Button
          key={i}
          variant={i === currentPage ? "dark" : "outline-dark"}
          onClick={() => handleClick(i)}
          className="mx-1"
          style={{
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            fontSize: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {i}
        </Button>
      );
    }

    // End Page
    if (endPage < totalPages) {
      if (showEllipsis && endPage < totalPages - 1) {
        pages.push(
          <span
            key="end-ellipsis"
            className="mx-1"
            style={{
              fontSize: "1.2rem",
              lineHeight: "40px",
              display: "inline-block",
              width: "40px",
              height: "40px",
              textAlign: "center",
            }}
          >
            ...
          </span>
        );
      }
      pages.push(
        <Button
          key={totalPages}
          variant="outline-dark"
          onClick={() => handleClick(totalPages)}
          className="mx-1"
          style={{
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            fontSize: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {totalPages}
        </Button>
      );
    }

    return pages;
  };

  return (
    <div className="d-flex justify-content-center align-items-center mt-2 mb-4">
      <Button
        variant="outline-dark"
        onClick={() => handleClick(currentPage - 1)}
        disabled={currentPage === 1}
        className="mx-1"
        style={{
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          fontSize: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {"<"}
      </Button>
      {renderPageNumbers()}
      <Button
        variant="outline-dark"
        onClick={() => handleClick(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="mx-1"
        style={{
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          fontSize: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {">"}
      </Button>
    </div>
  );
};

export default Pagination;
