import React from "react";
import Header from "../../components/Header/Header";
import ReviewerGuidelines from "../../components/guidelines/ReviewerGuidelines";
import Footer from "../../components/footer/Footer";

const ReviewerGuidelinesPage = () => {
  return (
    <>
      <Header />
      <ReviewerGuidelines />
      <Footer/>
    </>
  );
};

export default ReviewerGuidelinesPage;
