import React from 'react'
import Header from '../../components/Header/Header'
import Manuscript from '../../components/manuscript/Manuscript'
import Footer from '../../components/footer/Footer'

const Manuscriptpage = () => {
  return (
    <>
    <Header/>
    <Manuscript/>
    <Footer/>
    </>
  )
}

export default Manuscriptpage