import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";
import { UserService } from "../../../app/service/user.service";
import Select from "react-select";
import { Form, Button } from "react-bootstrap";

const UserJournalCreate = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [formData, setFormData] = useState<any>({
    journalTitle: "",
    journalNameId: "",
    ISSNNumber: "",
    description: "",
    journalImage: null,
  });

  const [errors, setErrors] = useState<any>({});
  const loginData: any = sessionStorage.getItem("loginData");
  const userData = JSON.parse(loginData);

  const handleInput = (e: any, selectedOption?: any) => {
    if (selectedOption) {
      // Handle react-select input
      const { value, id } = selectedOption; // Extract both value and id
      setFormData((prevState: any) => ({
        ...prevState,
        journalTitle: value,
        journalNameId: id, // Set the selected journal ID
      }));
    } else if (e.target.type === "file") {
      // Handle file input
      const { name, files } = e.target;
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: files[0], // Only handle single file upload
      }));
    } else {
      // Handle standard input fields
      const { name, value } = e.target;
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }

    // Reset errors for the field being changed
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [e?.target?.name || selectedOption?.name]: "",
    }));
  };

  const validate = () => {
    const newErrors: any = {};

    if (!formData.ISSNNumber) {
      newErrors.ISSNNumber = "ISSN Number is required";
    }

    if (!formData.description) {
      newErrors.description = "Description is required";
    }

    if (!formData.journalImage) {
      newErrors.journalImage = "Journal Image is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = (e: any) => {
    e.preventDefault();
    const loginData: any = sessionStorage.getItem("loginData");
    const data = JSON.parse(loginData);
    formData.userName = data.email;
    if (userData?.role?.roleName === "user") {
      formData.journalTitle = data?.journalName?.journalName;
      formData.journalNameId = data?.journalName?._id;
    }

    if (validate()) {
      const formDataToSubmit = new FormData();
      Object.keys(formData).forEach((key) => {
        formDataToSubmit.append(key, formData[key]);
      });

      AdminService.createJournalData(formDataToSubmit).then((res) => {
        if (res.status === "Fail") {
          toast.error(res.error);
        } else {
          toast.success(res.message);
          navigate("/user/journalsList");
        }
      });
    }
  };

  const handleCancel = () => {
    navigate("/user/journalsList");
  };

  useEffect(() => {
    getJournalNames();
  }, []);

  const getJournalNames = async () => {
    try {
      const response = await AdminService.allJournalNames("");
      setData(response.data);
    } catch (error) {
      console.error("Error fetching journal names:", error);
    }
  };

  // Include both id and value for options
  const journalOptions = data?.map((type: any) => ({
    value: type.journalName,
    label: type.journalName,
    id: type._id,
  }));

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Add New Journal</b>
          </h4>
          <Form>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="journalTitle" className="mt-3">
                  <Form.Label>Journal Title *</Form.Label>
                  {userData?.role?.roleName === "user" ? (

                    <Form.Control
                      type="text"
                      name="journalTitle"
                      value={
                        formData.journalTitle ||
                        userData?.journalName?.journalName
                      }
                      disabled
                    />
                  ) : (
                    <Select
                      options={journalOptions}
                      name="journalTitle"
                      placeholder="Select Journal"
                      onChange={(selectedOption: any) =>
                        handleInput(undefined, {
                          value: selectedOption.value,
                          id: selectedOption.id,
                          name: "journalTitle",
                        })
                      }
                    />
                  )}

                  {errors.journalTitle && (
                    <div className="text-danger">{errors.journalTitle}</div>
                  )}
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group controlId="ISSNNumber" className="mt-3">
                  <Form.Label>ISSN Number *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="ISSN Number"
                    name="ISSNNumber"
                    value={formData.ISSNNumber}
                    onChange={handleInput}
                    className={errors.ISSNNumber ? "is-invalid" : ""}
                  />
                  {errors.ISSNNumber && (
                    <div className="invalid-feedback">{errors.ISSNNumber}</div>
                  )}
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group controlId="description" className="mt-3">
                  <Form.Label>Description *</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    name="description"
                    value={formData.description}
                    onChange={handleInput}
                    rows={3}
                    className={errors.description ? "is-invalid" : ""}
                  />
                  {errors.description && (
                    <div className="invalid-feedback">{errors.description}</div>
                  )}
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group controlId="journalImage" className="mt-3">
                  <Form.Label>Journal Image *</Form.Label>
                  <Form.Control
                    type="file"
                    name="journalImage"
                    onChange={handleInput}
                    className={errors.journalImage ? "is-invalid" : ""}
                  />
                  {errors.journalImage && (
                    <div className="invalid-feedback">
                      {errors.journalImage}
                    </div>
                  )}
                </Form.Group>
              </div>
            </div>
            <div className="mt-3">
              <Button
                variant="primary"
                type="submit"
                className="me-2"
                onClick={handleRegister}
              >
                Create
              </Button>
              <Button variant="secondary" type="button" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default UserJournalCreate;
