import { DataTableCol } from "../../../components/tables/types";

export const UsersDataGridCols: DataTableCol[] = [
    {
        title: 'S.no',
        control: 'serialNo',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'User Name',
        control: 'userName',
        sortable: true,
        canShowColumn: true
    },
    // {
    //     title: 'Email',
    //     control: 'email',
    //     sortable: true,
    //     canShowColumn: true
    // },
    {
        title: 'Date of Join',
        control: 'dateOfJoining',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Password',
        control: 'password',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Role',
        control: 'roleName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        canShowColumn: true
    },
];