import React, { useContext, useState, useEffect } from "react";

export const AppContext = React.createContext({});
export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }: any) => {
  const [usersData, setUserData] = useState<any>(() => {
    const savedData = sessionStorage.getItem("loginData");
    return savedData ? JSON.parse(savedData) : {};
  });


  const handleLogin = (data: any) => {
    setUserData(data);
    sessionStorage.setItem("loginData", JSON.stringify(data));
    sessionStorage.setItem("token", data.token);
  };

  return (
    <AppContext.Provider value={{ handleLogin,usersData }}>
      {children}
    </AppContext.Provider>
  );
};
