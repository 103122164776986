import React, { useEffect } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/footer/Footer'
import Abstract from '../../components/journals/abstract'

const AbstractPage = () => {

  useEffect(()=>{
    scrollToTop()
  },[])

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};
  return (
    <div>
        <Header/>
        <Abstract/>
        <Footer/>
    </div>
  )
}

export default AbstractPage