import React, { useEffect } from 'react'
import Header from '../../components/Header/Header'
import Supportus from '../../components/supportus/Supportus'
import Footer from '../../components/footer/Footer'

const SupportusPage = () => {

  useEffect(()=>{
    scrollToTop()
  },[])

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};
  return (
    <div>
        <Header/>
        <Supportus/>
        <Footer/>
    </div>
  )
}

export default SupportusPage