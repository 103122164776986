import React, { useEffect, useState } from "react";
import { DataTableCol } from "./types";
import ReactPaginate from "react-paginate";
import { Dropdown, DropdownButton } from "react-bootstrap";

interface Props {
  tableData: any;
  TableCols: DataTableCol[];
  handleDeleteUser?: any;
  handleEditUser?: any;
  deleteRegisterInfo?: any;
  deletemanuscriptInfo?: any;
  deleteArticlesInfo?: any;
  handleEditMeet?: any;
  deleteQueriesInfo?: any;
  deleteCourseInfo?: any;
  handleEditUserJournal?: any;
  handleAccessData?: any;
  deleteEditorListInfo?: any;
  generateCertificate?: any;
  handleCgpaAccess?: any;
  setFormData?: any;
  deleteSignUpListInfo?: any;
  deleteJournalsDataInfo?: any;
  handleEditorialBoard?: any;
  handleEditArticles?: any;
  deleteContactusInfo?: any;
  handleAccessIssue?: any;

  // searchText?: any;
  // pageNumbers?: any;
  // pageNumber: (value: any) => void;
  // activePageNumber?: number;
  // editInfo?: any;
  // deleteInfo?: any;
  // onChange?: (value: any) => void;
  // onQuestionUpload?: (value: any) => void;
}

export const DataTable: React.FC<Props> = (props: Props) => {
  let count = 1;
  const [canShowDefaultArrow, setCanShowDefaultArrow] = useState(true);
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");
  const [tabledata, setTableData] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const items = props.tableData;
  const [showDropdown, setShowDropdown] = useState(false);
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);
  const loginData: any = sessionStorage.getItem("loginData");
  const data = JSON.parse(loginData);


  useEffect(() => {
    // const data = props.tableData;
  }, []);

  const onSortingField = (data: any) => {
    setCanShowDefaultArrow(false);
    const order =
      data === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(data);
    setSortingOrder(order);
    if (data) {
      const reversed = order === "asc" ? 1 : -1;
      const preparedData = props.tableData.sort(
        (a: any, b: any) => reversed * a[data].toString().localeCompare(b[data])
      );
      // console.log(preparedData);
    }
  };

  const handlePageClick = (e: any) => {
    const newOffset = (e.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };
  const DeleteUser = (data: any) => {
    if (props.handleDeleteUser) {
      props?.handleDeleteUser(data._id);
    }
    if (props.deleteRegisterInfo) {
      props?.deleteRegisterInfo(data._id);
    }
    if (props?.deletemanuscriptInfo) {
      props?.deletemanuscriptInfo(data._id);
    }
    if (props?.deleteEditorListInfo) {
      props?.deleteEditorListInfo(data._id);
    }
    if (props?.deleteArticlesInfo) {
      props?.deleteArticlesInfo(data._id);
    }
    if (props?.deleteCourseInfo) {
      props?.deleteCourseInfo(data._id);
    }
    if (props?.deleteJournalsDataInfo) {
      props?.deleteJournalsDataInfo(data._id);
    }
    if (props?.deleteSignUpListInfo) {
      props?.deleteSignUpListInfo(data._id);
    }
    if (props?.deleteContactusInfo) {
      props?.deleteContactusInfo(data._id);
    }
  };

  const EditUser = (data: any) => {
    if (props.handleEditUser) {
      props.handleEditUser(data);
    }
    if (props.handleEditArticles) {
      props.handleEditArticles(data);
    }
    if (props?.handleEditUserJournal) {
      props.handleEditUserJournal(data);
    }
    if (props?.handleEditorialBoard) {
      props?.handleEditorialBoard(data);
    }
  };
  const handleAccess = (data: any) => {
    if (props?.handleAccessData) {
      props?.handleAccessData(data);
    }
  };
  const handleCertificateSelection = (type: string, data: any) => {
    if (props?.handleAccessIssue) {
      props?.handleAccessIssue(type, data);
    }
  };
  const ClickCgpaAcess = (data: any) => {
    if (props?.handleCgpaAccess) {
      props?.handleCgpaAccess(data);
    }
  };
  const handleClickDate = (e: any) => {
    const { name, value } = e.target;
    props?.setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleViewFiles = (files: any) => {
    if (Array.isArray(files)) {
      files.forEach((fileUrl: string, index: number) => {
        setTimeout(() => {
          window.open(fileUrl, "_blank");
        }, index * 500);
      });
    } else if (files) {
      window.open(files, "_blank");
    } else {
      console.error("File URLs not found");
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table_new_ui">
                <thead className="text-center">
                  <tr>
                    {props.TableCols.map((el, index) => (
                      <React.Fragment key={index}>
                        {el.canShowColumn && (
                          <th
                            onClick={() =>
                              el.sortable ? onSortingField(el.control) : null
                            }
                          >
                            {
                              <span style={{ fontSize: "16px" }}>
                                {el.title}
                              </span>
                            }

                            {canShowDefaultArrow &&
                              index === 0 &&
                              el.sortable && (
                                <i className="bi bi-arrow-down cursor-pointer p-2 "></i>
                              )}
                            {sortingField && sortingField === el.control && (
                              <i
                                className={`${
                                  sortingOrder === "asc"
                                    ? "bi bi-arrow-down cursor-pointer p-2"
                                    : "bi bi-arrow-up cursor-pointer p-2"
                                }`}
                              ></i>
                            )}
                          </th>
                        )}
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-center">
                  {currentItems.map((data: any, i: number) => {
                    return (
                      <tr key={i}>
                        {props.TableCols.map((el: any, index: number) => (
                          <React.Fragment key={index}>
                            <td style={{ fontSize: "18px" }}>
                              {el.control === "manuscriptFile" ? (
                                // Check if data[el.control] is available and is an array
                                Array.isArray(data[el.control]) ? (
                                  data[el.control].map(
                                    (url: any, index: number) => {
                                      const trimmedUrl = url.trim();
                                      // Check if it's a PDF or an image
                                      const isPDF = trimmedUrl.endsWith(".pdf");
                                      const isImage = trimmedUrl.match(
                                        /\.(jpeg|jpg|gif|png|bmp|svg)$/
                                      );

                                      // Log each file URL being rendered
                                      console.log(
                                        `Rendering URL ${index + 1}:`,
                                        trimmedUrl
                                      );

                                      return (
                                        <a
                                          key={index} // Unique key for each file
                                          href={trimmedUrl} // The URL of the manuscript file
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            textDecoration: "none",
                                            marginRight: "5px",
                                          }}
                                        >
                                          {isImage ? (
                                            <img
                                              src={trimmedUrl}
                                              alt={`Manuscript ${index + 1}`}
                                              style={{
                                                width: "50px",
                                                height: "auto",
                                                cursor: "pointer",
                                              }}
                                            />
                                          ) : (
                                            <span>PDF Document</span>
                                          )}
                                        </a>
                                      );
                                    }
                                  )
                                ) : (
                                  <span>No Manuscript Available</span>
                                )
                              ) : (
                                // Render other data controls
                                data[el.control]
                              )}

                              {/* Serial number rendering */}
                              {el.control === "serialNo" && (
                                <span>{itemOffset + i + 1}</span>
                              )}

                              {/* View File button */}
                              {el.control === "ViewFile" && (
                                <button
                                  className="btn btn-success"
                                  onClick={() =>
                                    handleViewFiles(data.manuscriptFile)
                                  }
                                >
                                  View
                                </button>
                              )}

                              {/* Certificate actions */}
                              {el.isCerificate && (
                                <DropdownButton
                                  id="dropdown-basic-button"
                                  title="Action"
                                  variant="secondary"
                                >
                                  <Dropdown.Item
                                    href="#"
                                    onClick={() =>
                                      handleCertificateSelection(
                                        "currentIssues",
                                        data
                                      )
                                    }
                                  >
                                    Current Issues
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href="#"
                                    onClick={() =>
                                      handleCertificateSelection(
                                        "previousIssues",
                                        data
                                      )
                                    }
                                  >
                                    Previous Issues
                                  </Dropdown.Item>
                                </DropdownButton>
                              )}

                              {/* Actions column */}
                              {el.title === "Actions" && (
                                <div className="actions-view">
                                  <span style={{ cursor: "pointer" }}>
                                    {el.isAdd && (
                                      <button
                                        className="btn btn-info text-white"
                                        onClick={() => handleAccess(data)}
                                      >
                                        Access
                                      </button>
                                    )}
                                    {el.isAccess && (
                                      <button
                                        className="btn btn-info text-white"
                                        onClick={() => ClickCgpaAcess(data)}
                                      >
                                        Access
                                      </button>
                                    )}

                                    {el.isEdit && (
                                      <i
                                        className="bi bi-pencil-square cursor-pointer edit-icon p-2"
                                        onClick={() => EditUser(data)}
                                      ></i>
                                    )}
                                    {data?.role?.roleName!=="admin" && el.isDelete  && (
                                      <i
                                        className="bi bi-trash text-danger fw-bold icon-size cursor-pointer p-2"
                                        onClick={() => DeleteUser(data)}
                                      ></i>
                                    )}
                                    {el.isView && (
                                      <span>
                                        <i className="bi bi-eye-fill cursor-pointer p-2"></i>
                                      </span>
                                    )}
                                  </span>
                                </div>
                              )}
                            </td>
                          </React.Fragment>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {currentItems.length === 0 && (
              <div className="mt-3 text-center">No records found.</div>
            )}
            {currentItems.length > 0 && (
              //  <Pagination totaldatacount={props.tableData.length} />
              // <div className='pagination d-flex justify-content-end'>
              //     <Pagination
              //         activePage={1}
              //         itemsCountPerPage={10}
              //         totalItemsCount={450}
              //         pageRangeDisplayed={5}
              //         onChange={handlePageChange}
              //         itemClass='page-item'
              //     />
              // </div>
              <div className="d-flex justify-content-end mb-4">
                <ReactPaginate
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={4}
                  marginPagesDisplayed={0}
                  pageCount={pageCount}
                  previousLabel="<"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel=""
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
