import API from "../utility/axios";
export class UserService {
    static addRegister(data: any): Promise<any> {
        return API.post(`/createRegister`, data);
    }
    static userLogin(data: any): Promise<any> {
        return API.post(`/login`, data);
    }
    static submitManuscript(data: any): Promise<any> {
        return API.post(`/createCgpa`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    static createEditorJoining(data: any): Promise<any> {
        return API.post(`/createEditorJoining`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

}