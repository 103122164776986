import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import ContactInformation from "../contactInformation/ContactInfo";
import SubmissionPage from "../submissionPage/SubmissionPage";
import bannerBg from "../../assets/images/banner-guideless.png";

const AuthorGuidelines = () => {
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center text-center text-warning"
        style={{
          backgroundImage: `url(${bannerBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "200px",
          width: "100%",
        }}
      >
        <h3 className="fw-bold text-white">Author Guidelines for Submission</h3>
      </div>
      <Container className="pt-3 pb-3">
        <p className='pb-3 pt-3'>
          At <strong>SciencePhotons</strong>, we aim to provide a seamless,
          transparent, and professional publishing experience for all our
          authors. Please follow these comprehensive guidelines to ensure the
          smooth review and publication of your work.
        </p>
        <div
          className="p-3 mb-4"
          style={{
            backgroundColor: "#FFFDF7",
            borderRadius: "8px",
            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.16)",
          }}
        >
          <h4 className='fw-bold'>
            1. Submission Format
          </h4>
          <p>
            Manuscripts should be submitted in Microsoft Word format (DOC, DOCX)
            and RTF. This allows compatibility across platforms and makes
            editing and reviewing easier. Ensure that any Word document
            submissions are not locked or password-protected, allowing the
            editorial team to edit and format them efficiently. Figures should
            be submitted separately as image files. If multiple files are
            submitted, they should be compressed into ZIP or RAR format.
          </p>
          <h6 className='fw-bold'>Language</h6>
          <p>
            Submissions must be written in <strong>Standard English</strong>,
            ensuring clarity and consistency throughout the manuscript. We
            recommend non-native English speakers have their manuscripts
            proofread for language accuracy.
          </p>
          <h6 className='fw-bold'>Word Count</h6>
          <p>
            We impose no restrictions on word count or article length. Authors
            can submit manuscripts of any length required to convey their
            research effectively.
          </p>
          <h6 className='fw-bold'>Cover Letter</h6>
          <p>
            A cover letter should accompany your submission, highlighting the
            significance of the research and confirming the authors’ agreement
            to publication in accordance with our guidelines and ethical
            policies.
          </p>
        </div>

        <div
          className="p-3 mb-4"
          style={{
            backgroundColor: "#FFFDF7",
            borderRadius: "8px",
            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.16)",
          }}
        >
          <h4 className='fw-bold'>
            2. Article Types
          </h4>
          <h6 className='fw-bold'>Original Research Articles</h6>
          <p>
            These articles present new findings from original research conducted
            by the authors. They typically include an introduction, methods,
            results, discussion, and conclusion, contributing novel insights to
            the field.
          </p>
          <h6 className='fw-bold'>Review Articles</h6>
          <p>
            Review articles offer comprehensive analysis and synthesis of
            existing research on a specific topic. These articles provide
            context and evaluate advancements in the field.
          </p>
          <h6 className='fw-bold'>Case Reports</h6>
          <p>
            These are descriptions of novel or unique clinical cases. Case
            reports include patient symptoms, diagnosis, treatment, and
            outcomes, often supported by a brief review of related literature.
          </p>
          <h6 className='fw-bold'>Editorials</h6>
          <p>
            Editorials are concise articles written by editors or guest authors
            offering opinions on key topics relevant to the journal's focus.
          </p>
          <h6 className='fw-bold'>Commentaries</h6>
          <p>
            Commentary pieces provide critical analysis or viewpoints on
            recently published research or current issues within the field.
          </p>
          <h6 className='fw-bold'>Corrigenda</h6>
          <p>
            These are corrections issued for significant errors identified in
            previously published articles.
          </p>
          <h6 className='fw-bold'>Expert Opinions</h6>
          <p>
            Expert opinions are insights from specialists in the field,
            discussing the potential direction or importance of new developments
            in research.
          </p>
          <h6 className='fw-bold'>Letters to the Editor</h6>
          <p>
            Letters are typically brief discussions that either support or
            dispute findings of previously published work.
          </p>
          <h6 className='fw-bold'>Perspective Articles</h6>
          <p>
            These articles present the author’s viewpoint on emerging or
            important issues within the journal’s scope.
          </p>
        </div>

        <div
          className="p-3 mb-4"
          style={{
            backgroundColor: "#FFFDF7",
            borderRadius: "8px",
            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.16)",
          }}
        >
          <h4 className='fw-bold'>3. Structure</h4>
          <h5
            style={{
              fontWeight: 600,
              fontSize: "18px",
              textDecoration: "underline",
            }}
          >
            Title Page
          </h5>
          <h6 className='fw-bold'>Title</h6>
          <p>
            The title should be concise (25 words or less) and free from
            abbreviations. It must reflect the focus of the research while
            remaining clear and professional.
          </p>
          <h6 className='fw-bold'>Author Information</h6>
          <p>
            Provide the full names, institutional affiliations, and contact
            details (including email addresses) of all contributing authors.
            Ensure proper credit is given to all contributors in the correct
            order of significance.
          </p>
          <h6 className='fw-bold'>Abstract & Keywords</h6>
          <p>
            All manuscripts must include an abstract of up to 300 words. The
            abstract should succinctly summarize the research background,
            methods, results, and conclusions. Include 3-5 keywords that clearly
            represent the core themes of the article. Definitions for any
            abbreviations used should also be provided in this section.
          </p>
          <h6 className='fw-bold'>Main Text:</h6>
          <p>Original Research Articles should include Introduction, Methods, Results, 
Discussion, and Conclusion. Other manuscripts should follow specific formats relevant to the 
type.</p>
        </div>
          <div className="p-3 mb-4"
          style={{
            backgroundColor: "#FFFDF7",
            borderRadius: "8px",
            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.16)",
          }}>
            <h4 className='fw-bold'>4. Introduction</h4>
            <p>The introduction should outline the study's background, citing relevant literature, and identifying 
            the gap your research addresses.</p>
            <h6 className='fw-bold'>Materials and Methods</h6>
          <p>Provide a detailed description of the study design, materials, and methodologies used. This 
          section must be clear enough for others to replicate the research.</p>
          <h6 className='fw-bold'>Results Presentation</h6>
                    <p>Results should be presented in the past tense and must clearly support the conclusions. Data 
                    should be accurate, with appropriate statistical analyses where necessary.</p>
          <h6 className='fw-bold'>Conclusion/Discussion</h6>
                    <p>These sections can be combined or presented separately. In either case, they should offer a 
                    thorough interpretation of the results, highlighting the implications of the findings.</p>
          <h6 className='fw-bold'>Acknowledgment Section</h6>
                    <p>Acknowledge any non-author contributors, including technical assistance or funding sources. 
          Grant numbers, institutional affiliations, or other support should be clearly stated.
          </p>
          <h6 className='fw-bold'>References</h6>
                    <p>Only cite <strong>published or in-press works.</strong> Use a numbered citation system and follow the journal’s 
          reference style. Authors are responsible for ensuring the accuracy and completeness of their 
          references.</p>
          <ul>
            <li><strong>Format: </strong>List references on a new page, double-spaced, and numbered consecutively as 
            they appear in the text. Use Arabic numerals in parentheses for in-text citations (e.g., 5). 
            When listing citations, separate individual ones with commas. For three or more 
            consecutive citations, use a range. For example: (2, 5–9, 30).</li>
            <li><strong> Author Listing: </strong>If there are five or fewer authors, list them all. For six or more, list the 
            first five followed by "et al."</li>
            <li><strong> Journal Names: </strong>Use the abbreviated form of journal names according to Index 
            Medicus/Medline. If unsure, write the full name.</li>
            <li><strong>Unpublished Work: </strong>For studies accepted but not yet published, use "in press" in place 
            of volume and page numbers.
            </li>
          </ul>
          <h6 className='fw-bold'>Examples of reference style:</h6>
          <ul>
            <li><strong>1. Journal Article: </strong> Smith J, Brown L, Taylor P, et al. The Effects of Nutrition on Heart 
            Disease: A Global Study. International Journal of Health. 2020;15(3):150-162</li>
            <li><strong>2. Book: </strong>: Johnson L, Edwards M, Roberts J, eds. Comprehensive Guide to Clinical 
            Practices. 10th ed. New York, NY: Health Press; 2018:350-370.</li>
            <li><strong>3. Web Page: </strong>WHO Guidelines for Infection Prevention and Control. 
            <a href='https://www.who.int/publications/infection-prevention-guidelines' style={{whiteSpace:"nowrap"}}>https://www.who.int/publications/infection-prevention-guidelines</a>
            </li>
          </ul>
          <h6 className='fw-bold'>Tables</h6>
          <p>Tables should be kept simple, submitted on separate pages, and clearly labeled with appropriate 
          headings and brief legends explaining the content. Tables can either be placed at the end of the 
          manuscript or embedded within the text, provided they are clearly referenced.</p>
          <h6 className='fw-bold'>Figures</h6>
          <p>Submit figures in <strong>high-resolution TIFF or JPEG formats</strong>. Figures should be embedded within 
          the manuscript or submitted separately. Ensure all figures are clearly labeled and easily readable.
          </p>
          <h6 className='fw-bold'>Equations</h6>
          <p>Use <strong>Math Type</strong> for any equations. If necessary, TIFF or EPS formats can be used for greater 
          clarity. Number equations sequentially in the manuscript, and make sure they are clearly 
          referenced in the text.</p>
          <h6 className='fw-bold'>Proofs</h6>
          <p>Authors will receive <strong>galley proofs</strong> to review for typographical or minor errors before the article 
          is published. This is your final chance to make minor corrections. Authors will have 48 hours to 
          review and return proofs</p>
          </div>
        <div
          className="p-3 mb-4"
          style={{
            backgroundColor: "#FFFDF7",
            borderRadius: "8px",
            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.16)",
          }}
        >
          <h4 className='fw-bold'>
            5. Article Processing Charges (APC)
          </h4>
          <p>
            Our Article Processing Charges (APC) help us publish high-quality
            research and make it available for free worldwide. The APC covers
            costs for peer review, editing, production, and online hosting,
            allowing us to support authors fairly.
          </p>
          <p>
            We are transparent about how the APC is used for rigorous peer
            review and quality publishing. This fee helps every article meet
            high standards and reach a global audience.
          </p>
          <p>
            As an open-access journal, we do not rely on subscriptions or other
            funding. Instead, we depend on the APC, which allows us to focus on
            sharing great research. We understand that some authors, especially
            from low-income countries, may struggle with costs. To help, we
            offer discounts for eligible submissions. Our goal is to support
            important research and make it accessible to everyone.
          </p>
        </div>

        <div
          className="p-3 mb-4"
          style={{
            backgroundColor: "#FFFDF7",
            borderRadius: "8px",
            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.16)",
          }}
        >
          <h4 className='fw-bold'>
            6. Open Access and Licensing
          </h4>
          <p>
            We follow a <strong>gold open access model</strong>, allowing your
            research to be freely accessible to the global scientific community
            without any barriers. All articles are published under the{" "}
            <strong>Creative Commons Attribution 4.0 (CC BY 4.0)</strong>, which
            permits unrestricted use, distribution, and reproduction in any
            medium, provided the original work is properly cited. This ensures
            maximum visibility and impact for your research.
          </p>
          <h6 className='fw-bold'>Withdrawal Policy</h6>
          <p>Authors may request to withdraw a submitted manuscript before it is accepted. To withdraw, the 
corresponding author must send a request with the reason for withdrawal with in 24 hrs. 
Withdrawals after acceptance are discouraged but may be considered in exceptional cases. Once 
published, withdrawal is not allowed, except in cases of serious ethical issues, where retraction 
may be considered.
</p>
<h6 className='fw-bold'>Ethical Considerations and Conflict of Interest</h6>
          <p>Authors must adhere to the highest ethical standards. Any potential conflicts of interest should 
be disclosed in the manuscript. If no conflict exists, please state this explicitly. All research 
involving human or animal subjects must comply with ethical standards and include appropriate 
ethics committee approvals.
</p>
<h6 className='fw-bold'>Authorship</h6>
          <p>Authorship should include only those who have made significant contributions to the research, 
like planning, conducting, or analyzing the study. All authors share responsibility for the work's 
accuracy. Those who helped but don’t meet these criteria (like technical or writing support) 
should be mentioned in the acknowledgments. The corresponding author must ensure that all 
listed authors approve the final version and agree to its submission.
</p>
<h6 className='fw-bold'>Plagiarism</h6>
          <p>We kindly ask authors to avoid any form of plagiarism, including the copying of text, images, or 
data from other sources without proper acknowledgment. Even if cited, direct duplication of 
content from other works is not acceptable. We follow COPE guidelines to address instances of 
plagiarism.
</p>
        </div>

        <div
          className="p-3 mb-4"
          style={{
            backgroundColor: "#FFFDF7",
            borderRadius: "8px",
            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.16)",
          }}
        >
          <h4 className='fw-bold'>
            7. Data Fabrication and Falsification
          </h4>
          <p>
            Authors are requested to provide accurate and honest data.
            Fabricating or falsifying results, or omitting conflicting data
            without justification, is not allowed and will be subject to
            investigation.
          </p>

          <h6 className='fw-bold'>Final Note</h6>
          <p>
            These guidelines have been created to ensure a smooth, efficient,
            and professional process for the submission, review, and publication
            of your work. At SciencePhotons, we are dedicated to promoting the
            highest standards in open-access publishing and look forward to
            receiving your valuable contributions.
          </p>
          <p>
            Should you have any questions or need further clarification, please
            don’t hesitate to contact our editorial team for support.
          </p>
        </div>
        <ContactInformation />
      </Container>
      <SubmissionPage />
    </>
  );
};

export default AuthorGuidelines;
