import React from "react";
import { FaHome } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
const AdminDashboard = () => {
  const loginData: any = sessionStorage.getItem("loginData");
  const data = JSON.parse(loginData);

  return (
    <div className="admin-dashboard">
      <div className="icon-container">
        <FaHome className="home-icon" />
      </div>
      <div className="card1">
        <>
          {data?.role?.roleName === "admin" ? (
            <>
              <h2>Welcome to the Admin</h2>
              <p>
                Journal Website:{" "}
                <Link to="https://www.sciencephotons.com/">Click Here</Link>
              </p>
              <p>No of Users: {data?.editorBoardCount || 0}</p>
              <p>No of Issues in Journal: {data?.articleCount || 0}</p>
            </>
          ) : (
            <>
              <h2>{data?.journalName?.journalName || "-"}</h2>
              <p>
                Journal Website:{" "}
                <Link to="https://www.sciencephotons.com/">Click Here</Link>
              </p>
              <p>No of Editors: {data?.editorBoardCount || 0}</p>
              <p>No of Issues in Journal: {data?.JournalCount || 0}</p>
              <p>Total No of articles in Journal: {data?.articleCount || 0}</p>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default AdminDashboard;
