import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const SubmissionPage = () => {
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: "#FFFDF7" }}>
    <Container>
      <Row  className="p-5">
        <Col md={8}>
          <h2>
            <b>Submit Your Manuscript</b>
          </h2>
          <p>
            <h4 style={{ color: "#FFB901" }}>
              Ready to share your groundbreaking research with the world?
            </h4>
            <h6>
              Submit your article here and become a part of our vibrant
              community dedicated to advancing scientific knowledge. We look forward to collaborating
              with you!
            </h6>
          </p>
        </Col>
        <Col md={4} className="mt-5 text-end">
          <Button
            className="button-color px-3 py-3"
            onClick={() => navigate("/submission-page-urls")}
          >
            Submit Manuscript
          </Button>
        </Col>
      </Row>
      </Container>
    </div>
  );
};

export default SubmissionPage;
