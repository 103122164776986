import React from 'react'
import Header from '../../components/Header/Header'
import SpecialIssues from '../../components/journals/specialIssues'
import Footer from '../../components/footer/Footer'

const SpecialissuesPage = () => {
  return (
    <div>
        <Header/>
        <SpecialIssues/>
        <Footer/>
    </div>
  )
}

export default SpecialissuesPage