import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaEnvelope, FaMapMarkerAlt, FaGlobe } from "react-icons/fa";
import {
  FaTwitter,
  FaFacebookF,
  FaPinterestP,
  FaLinkedinIn,
} from "react-icons/fa";
import { Link } from 'react-router-dom'; // Import Link
import logoImg from "../../assets/images/FINAL LOGO AAABB copy.jpg";
import footerImg from '../../assets/images/footer.png'

const Footer = () => {
  return (
    <footer className="footer bg-color text-white py-4">
      <Container>
        <Row>
          <p className="mt-2">Science Photons Licence</p>
          <Col md={4}>
            <img
              src={logoImg}
              alt="Logo"
              className="mb-2 mt-2 rounded logo-size"
              style={{width:'300px', height:'110px'}}
         
            />
            <br/>
            <img
              src={footerImg}
              alt="Logo"
              className="mb-2 mt-2 rounded"
          
            />
            <p className="mt-2">This work is licensed under a Creative Commons
              <br />
              Attribution 4.0 International License.</p>
          </Col>
          <Col md={4}>
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/home" className="text-white">Home</Link>
              </li>
              <li>
                <Link to="/aboutus" className="text-white">About</Link>
              </li>
              <li>
                <Link to="/editor-guidelines" className="text-white">Editor Guidelines</Link>
              </li>
              <li>
                <Link to="/author-guidelines" className="text-white">Author Guidelines</Link>
              </li>
              <li>
                <Link to="/reviewer-guidelines" className="text-white">Reviewer Guidelines</Link>
              </li>
              <li>
                <Link to="/contact-us" className="text-white">Contact</Link>
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <h5>Contact Us</h5>
            {/* <p>
              <FaMapMarkerAlt /> 19C Trolley Square, Wilmington, DE 19806
            </p> */}
            <p>
              <FaEnvelope /> Email: info@sciencephotons.com
            </p>
            <p>
              <FaGlobe /> Web: https://www.sciencephotons.com/
            </p>
            <div className="social-icons mt-3">
              <div className="social-icons mt-3">
                <Link to="#twitter" className="text-white">
                  <FaTwitter />
                </Link>
                <Link to="#facebook" className="mx-2 text-white">
                  <FaFacebookF />
                </Link>
                <Link to="#pinterest" className="mx-2 text-white">
                  <FaPinterestP />
                </Link>
                <Link to="#linkedin" className="mx-2 text-white">
                  <FaLinkedinIn />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <p className="text-center">
              &copy; Science Photons. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
