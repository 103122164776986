import React from "react";
import Container from "react-bootstrap/Container";
import SubmissionPage from "../submissionPage/SubmissionPage";
import ContactInformation from "../contactInformation/ContactInfo";
import bannerBg from "../../assets/images/banner-guideless.png";

const ReviewerGuidelines = () => {
  return (
    <>
      {/* <div className="bg-dark pt-5 pb-5">
        <h3 className="pt-3 text-white text-center">Reviewer Guidelines</h3>
      </div> */}
      <div
        className="d-flex align-items-center justify-content-center text-center text-warning"
        style={{
          backgroundImage: `url(${bannerBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "200px",
          width: "100%",
        }}
      >
        <h3 className="fw-bold text-white">Reviewer Guidelines</h3>
      </div>
      <Container className="pt-3 pb-3">
      <section style={{padding: '2rem 0' }}>
        <div
          className="p-5"
          style={{
            backgroundColor: '#FFFDF7',
            boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.16)',
            borderRadius: '8px',
          }}
        >
        <h4 className='fw-bold'>1. Role and Responsibilities</h4>
        <p>
          As a reviewer for SciencePhotons, you are entrusted with the following
          key responsibilities:
        </p>
        <ul>
          <li>
            <strong>Provide Unbiased Evaluation:</strong> Offer a thorough,
            impartial, and constructive evaluation of the manuscript. Critically
            analyze the research's strengths and weaknesses without bias or
            prejudice, maintaining the integrity of the review process.
          </li>
          <li>
            <strong>Assess Quality and Relevance:</strong> Evaluate the
            manuscript’s scientific quality, originality, and relevance to the
            journal’s scope. Key considerations include methodological rigor,
            significance of findings, clarity, and adherence to ethical
            standards.
          </li>
          <li>
            <strong>Recommend Decisions:</strong> Based on your analysis,
            recommend one of the following outcomes:
            <ul>
              <li>
                <strong>Accept:</strong> The manuscript meets the journal's
                standards for publication.
              </li>
              <li>
                <strong>Minor Revisions:</strong> Requires small adjustments
                without substantial reworking.
              </li>
              <li>
                <strong>Major Revisions:</strong> Needs significant changes
                before it can be reconsidered for publication.
              </li>
              <li>
                <strong>Reject:</strong> The manuscript does not meet the
                journal’s standards and should not be published.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          Your decision should be backed by constructive comments to help the
          author improve their work.
        </p>

        <h4  className='fw-bold'>2. Confidentiality</h4>
        <p>
          All materials provided during the review process are strictly
          confidential:
        </p>
        <ul>
          <li>
            <strong>Confidentiality of Content:</strong> Do not disclose the
            manuscript content or share it with others without the editor’s
            permission.
          </li>
          <li>
            <strong>Avoid Unauthorized Sharing:</strong> Refrain from discussing
            the manuscript with others without editorial consent.
          </li>
        </ul>

        <h4 className='fw-bold'>3. Conflict of Interest</h4>
        <p>
          Transparency and objectivity are vital to the review process.
          Reviewers must disclose potential conflicts:
        </p>
        <ul>
          <li>
            <strong>Disclosure of Conflicts:</strong> Notify the editor if you
            have any personal, professional, or financial relationships that may
            affect your impartiality.
          </li>
          <li>
            <strong>Recusal:</strong> If a conflict exists, recuse yourself from
            reviewing the manuscript and inform the editorial team promptly.
          </li>
        </ul>

        <h4 className='fw-bold'>4. Timeliness</h4>
        <p>Meeting deadlines is crucial for an efficient review process:</p>
        <ul>
          <li>
            <strong>Complete Reviews on Time:</strong> We request reviews be
            completed within 4-6 weeks. This helps maintain the journal’s
            publication schedule.
          </li>
          <li>
            <strong>Extensions:</strong> If you cannot meet the deadline, inform
            the editor as soon as possible to request an extension or suggest
            alternative reviewers.
          </li>
        </ul>

        <h4 className='fw-bold'>5. Review Format</h4>
        <p>
          To ensure consistent and clear feedback, include the following
          components in your review:
        </p>
        <ul>
          <li>
            <strong>Overall Comments:</strong> Start with a brief overview,
            summarizing the manuscript's strengths and weaknesses, its
            significance, and its relevance to the journal’s scope.
          </li>
          <li>
            <strong>Specific Comments:</strong> Provide detailed,
            section-by-section feedback, focusing on:
            <ul>
              <li>
                <strong>Introduction:</strong> Does the introduction provide
                adequate context?
              </li>
              <li>
                <strong>Methods:</strong> Are methodologies appropriate and
                clearly described? Can the study be replicated?
              </li>
              <li>
                <strong>Results:</strong> Are results well-presented and do they
                support the conclusions?
              </li>
              <li>
                <strong>Discussion:</strong> Does the discussion address
                limitations and implications?
              </li>
              <li>
                <strong>Ethical Standards:</strong> Does the research adhere to
                ethical guidelines, including human or animal rights and data
                integrity?
              </li>
            </ul>
          </li>
          <li>
            <strong>Recommendation:</strong> Conclude with a clear
            recommendation for the manuscript, as outlined in section 1.3,
            supported by your specific comments.
          </li>
        </ul>

        <h4 className='fw-bold'>6. Ethical Considerations</h4>
        <p>
          Maintaining ethical standards is central to peer review and scientific
          research:
        </p>
        <ul>
          <li>
            <strong>Adherence to Ethical Guidelines:</strong> Ensure the
            manuscript complies with ethical research guidelines, including
            responsible use of human or animal subjects and proper data
            handling.
          </li>
          <li>
            <strong>Detection of Ethical Violations:</strong> If you suspect
            ethical issues like plagiarism or data fabrication, notify the
            editor immediately so appropriate actions can be taken.
          </li>
        </ul>

        <h4  className='fw-bold'>7. Additional Support</h4>
        <p>
          If you have questions or need clarification during the review process,
          feel free to reach out to our editorial team. We value your
          contribution in upholding the high standards of SciencePhotons.
        </p>

        {/* <p className="fw-bold">Submit Your Review:</p>
        <p>
          After completing your review, submit your feedback through our online
          submission system or email it directly to{" "}
          <a href="mailto:sciencephotons@gmail.com">sciencephotons@gmail.com</a>
          .
        </p> */}
        </div>
        </section>
        <ContactInformation />
      </Container>
      <SubmissionPage />
    </>
  );
};

export default ReviewerGuidelines;
