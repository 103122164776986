import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row, Toast } from 'react-bootstrap';
import Select from 'react-select'; // Import react-select
import { Header } from '../../dashboard/dashboardHeader';
import { Sidebar } from '../../dashboard/sidebar';
import { AdminService } from '../../../app/service/admin.service';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';

const EditorBoard = () => {
    const [formData, setFormData] = useState<any>({
        editorName: '',
        editorAffiliation: '',
        editorDepartment: '',
        editorUniversityName: '',
        editorCountryName: '',
        editorType: '',
        editorBiography: '',
        editorResearchInterest: '',
        editorJournalName: '',
        editorEmail: "",
        editorContactNubmer: "",
        editorImage: null // State to hold the image file
    });
    
    const navigate = useNavigate();
    const { state } = useLocation();
    const [errors, setErrors] = useState<any>({});
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('success');
    const [journalOptions, setJournalOptions] = useState<any[]>([]); // State to store journal options

    useEffect(() => {
        getJournalNames(); // Fetch journal names on component mount
        if (state) {
            setFormData(state);
        }
    }, [state]);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevData: any) => ({ ...prevData, [name]: value }));
    };

    const handleImageChange = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            setFormData((prevData: any) => ({
                ...prevData,
                editorImage: e.target.files[0], // Set the selected file
            }));
        }
    };

    // Handle change for react-select
    const handleSelectChange = (selectedOption: any) => {
        setFormData((prevData: any) => ({
            ...prevData,
            editorJournalName: selectedOption ? selectedOption.id : '', // Update state with selected value
        }));
    };

    const validate = () => {
        const newErrors: any = {};
        if (!formData.editorName) newErrors.editorName = 'Editor Name is required';
        if (!formData.editorEmail) newErrors.editorEmail = 'Editor Email is required';
        if (!formData.editorContactNubmer) newErrors.editorContactNubmer = 'Editor Contact Nubmer is required';
        if (!formData.editorAffiliation) newErrors.editorAffiliation = 'Editor Affiliation is required';
        if (!formData.editorDepartment) newErrors.editorDepartment = 'Editor Department is required';
        if (!formData.editorUniversityName) newErrors.editorUniversityName = 'Editor University Name is required';
        if (!formData.editorCountryName) newErrors.editorCountryName = 'Editor Country Name is required';
        if (!formData.editorType) newErrors.editorType = 'Editor Type is required';
        if (!formData.editorJournalName) newErrors.editorJournalName = 'Editor Journal Name is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        formData.editorId = state?._id;
        
        if (validate()) {
            try {
                const formDataToSubmit = new FormData();
                
                // Append all form fields to FormData
                Object.keys(formData).forEach(key => {
                    formDataToSubmit.append(key, formData[key]);
                });

                const response = await AdminService.updateEditor(formDataToSubmit);
                toast.success(response.message);
                navigate("/admin/editorialList");
            } catch (error) {
                setToastMessage('Error updating Editor Board');
                setToastType('error');
                setShowToast(true);
            }
        }
    };

    const getJournalNames = async () => {
        try {
            const response = await AdminService.allJournalNames("");
            const options = response.data.map((type: any) => ({
                value: type.journalName,
                label: type.journalName,
                id: type._id, // Assuming '_id' is the unique identifier for the journal
            }));
            setJournalOptions(options);
        } catch (error) {
            console.error("Error fetching journal names:", error);
        }
    };

    const handleCancel = () => {
        navigate('/admin/editorialList')
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Header />
                </div>
                <div className="row">
                    <div className="col-md-2 h-100 px-0">
                        <Sidebar />
                    </div>
                    <div className="col-md-10 content-scroll content-bg px-3 py-3">
                        <h3>Create Editor Board</h3>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Editor Name *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Editor Name"
                                            name="editorName"
                                            value={formData.editorName}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.editorName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.editorName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Editor Email *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Editor Email"
                                            name="editorEmail"
                                            value={formData.editorEmail}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.editorEmail}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.editorEmail}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Editor Contact Nubmer *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Editor Contact Nubmer"
                                            name="editorContactNubmer"
                                            value={formData.editorContactNubmer}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.editorContactNubmer}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.editorContactNubmer}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Editor Affiliation *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Editor Affiliation"
                                            name="editorAffiliation"
                                            value={formData.editorAffiliation}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.editorAffiliation}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.editorAffiliation}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Editor Department *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Editor Department"
                                            name="editorDepartment"
                                            value={formData.editorDepartment}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.editorDepartment}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.editorDepartment}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Editor University Name *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Editor University Name"
                                            name="editorUniversityName"
                                            value={formData.editorUniversityName}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.editorUniversityName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.editorUniversityName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Editor Country Name *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Editor Country Name"
                                            name="editorCountryName"
                                            value={formData.editorCountryName}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.editorCountryName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.editorCountryName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Editor Type *</Form.Label>
                                        <Form.Select
                                            name="editorType"
                                            value={formData.editorType}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.editorType}
                                        >
                                            <option value="">Select Editor Type</option>
                                            <option value="Guest Editor">Guest Editor</option>
                                            <option value="Editor-in-Chief">Editor-in-Chief</option>
                                            <option value="Associate Editor">Associate Editor</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.editorType}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Editor Journal Name *</Form.Label>
                                        <Select
                                            options={journalOptions}
                                            onChange={handleSelectChange}
                                            placeholder="Select Journal Name"
                                            classNamePrefix="select"
                                        />
                                        {errors.editorJournalName && <div className="text-danger">{errors.editorJournalName}</div>}
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Editor Biography *</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Enter Editor Biography"
                                            name="editorBiography"
                                            value={formData.editorBiography}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.editorBiography}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.editorBiography}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Editor Research Interest *</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Enter Editor Research Interest"
                                            name="editorResearchInterest"
                                            value={formData.editorResearchInterest}
                                            onChange={handleInputChange}
                                            isInvalid={!!errors.editorResearchInterest}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.editorResearchInterest}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                {/* New field for Editor Image */}
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Editor Image *</Form.Label>
                                        <Form.Control
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            isInvalid={!formData.editorImage && showToast} // Show error if image is not provided
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {showToast && !formData.editorImage && 'Editor Image is required'}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                            <Button variant="secondary" onClick={handleCancel} className='ms-4'>
                                Cancel
                            </Button>
                        </Form>
                        <Toast
                            onClose={() => setShowToast(false)}
                            show={showToast}
                            delay={3000}
                            autohide
                            bg={toastType === 'success' ? 'success' : 'danger'}
                        >
                            <Toast.Body>{toastMessage}</Toast.Body>
                        </Toast>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditorBoard;
