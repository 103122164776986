import { DataTableCol } from "../../../components/tables/types";

export const articlesDataGridCols: DataTableCol[] = [
    {
        title: 'S.no',
        control: 'serialNo',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Title',
        control: 'title',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Article Type',
        control: 'articleType',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Article Image',
        control: 'ViewFile',
        sortable: false,
        canShowColumn: true
    },
    {
        title: 'Date',
        control: 'createdAt',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Doi Number',
        control: 'doiNumber',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        canShowColumn: true,
        isCerificate:true
    },
];