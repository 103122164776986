import React from "react";
import { Button } from "react-bootstrap";
import SubmissionPage from "../submissionPage/SubmissionPage";
import bannerBg from "../../assets/images/banner-guideless.png";

const OpenAccess = () => {
  return (
    <div>
      <div className="bg-dark pt-5 pb-5" style={{backgroundImage: `url(${bannerBg})`}}>
        <h3 className="pt-3 text-white text-center side-font">Open Access</h3>
      </div>
      <div className="container mt-4">
        <p>
        At <strong>Science Photons</strong>, we are committed to providing high-quality, open-access scientific content that advances knowledge and fosters innovation. As an open-access journal, we do not rely on subscriptions or traditional funding sources. Instead, we depend solely on Article Processing Charges (APC) to cover essenAt Science Photons, we are committed to making research accessible to everyone, everywhere. Open Access (OA) ensures that published articles are freely available to the public, breaking down barriers to knowledge and fostering a global exchange of information. By choosing Open Access, authors increase the visibility and impact of their work, allowing researchers, students, and professionals to access and build upon it without restrictions.tial publishing costs, including peer review, editing, and hosting. This transparent model ensures our focus remains on delivering exceptional research and supporting the global scientific community.
        </p>
        <div className="p-3 mb-4 mt-4"
          style={{
            backgroundColor: '#FFFDF7',
            boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.16)',
            borderRadius: '8px',
          }}>
        <h3>Benefits of Open Access:</h3>
        <ul>
          <li>
            <strong>Global Reach:</strong> Your research is accessible
            worldwide, expanding its impact and readership.
          </li>
          <li>
            <strong>Increased Citations:</strong> Open Access articles are more
            likely to be cited, enhancing the recognition of your work.
          </li>
          <li>
            <strong>No Paywalls:</strong> Readers can access your articles for
            free, ensuring that knowledge is not limited by financial
            constraints.
          </li>
          <li>
            <strong>Collaboration:</strong> Open Access fosters collaboration
            among researchers, accelerating scientific progress.
          </li>
        </ul>
        <p>
          Join us in advancing science by choosing Open Access, where knowledge
          is shared freely for the benefit of all.
        </p>
      </div>
        </div>
      {/* <div
      style={{
        backgroundColor: '#095F60',
        color: '#FFFFFF',
        textAlign: 'center'
      }}
      className="d-flex flex-column justify-content-center align-items-center p-4 gap-3"
    >
      <h2>For more details, visit our</h2>
      <Button className="bg-white text-primary border-0">
        Fee Waivers page
      </Button>
      <p 
        className="custom-text"
        style={{
          width: '100%',
          maxWidth: '500px' 
        }}
      >
        Thank you for choosing Science Photons as your publishing platform. Together, we can illuminate the path of scientific discovery.
      </p>
    </div> */}
    <SubmissionPage/>
</div>
  );
};

export default OpenAccess;
