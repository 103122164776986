import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserService } from "../../app/service/user.service";
import { useAppContext } from "../context/AppContext";

export const Login = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { handleLogin }: any = useAppContext(); // <-- use "handleLogin"

  const onClickLogin = async (e: any) => {
    e.preventDefault();
    let payload = {
      userName,
      password,
    };
    await UserService.userLogin(payload)
      .then(async (res: any) => {
        sessionStorage.setItem("token", res?.data?.token);
        if (res?.status === "Success") {
          toast.success(res.message);
          await handleLogin(res.data);
          if (res?.data?.userKey) {
            navigate("/forpassword");
          } else {
            navigate("/dashboard");
          }
        } else {
          toast.error(res.message);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-10 col-sm-6 col-md-6 col-lg-4 mx-auto my-5 login-form">
          <div className="d-flex flex-column justify-content-center my-auto">
            <div className="m-4">
              <div className="Login-form-content">
                <h3 className="Login-form-title text-center">Log In</h3>
                <div className="mt-3">
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>User Name *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Your User Name *"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="mt-3">
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Your Password *"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="text-center mt-3">
                <button
                  className="custom-bg-btn border-0 py-1 text-white px-5 mt-2"
                  onClick={onClickLogin}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
