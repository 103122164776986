import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { toast } from "react-toastify";
import { AdminService } from "../../app/service/admin.service";
import { Col, Container, Row } from "react-bootstrap";

import cardImg from "../../assets/images/Card.png";

const CarouselComponent = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getAllHeaderData();
  }, []);

  const getAllHeaderData = () => {
    const requestData = {
      search: "",
      start: 1,
      count: 10,
    };

    AdminService.allHeaderData(requestData).then((res) => {
      if (res.status === "Success") {
        if (res.data) {
          setData(res.data);
        }
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div className="carousel-data">
      <Container>
        <Carousel controls={false}>
          {data.map((item: any) => (
            <Carousel.Item key={item._id}>
              <Row>
                {/* Left Column: Content */}
                <Col md={8}>
                  <div className="mt-5 mb-3 bg-carousel-card pt-3 pb-3 d-flex align-items-center">
                    {/* <h1 className="text-white pt-5 px-4 py-4 carousel-des ">
                      <span
                        style={{
                          display: "block",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {item.description.split(" ").slice(0, 2).join(" ")}
                      </span>
                      <span style={{ display: "block" }}>
                        {item.description.split(" ").slice(2, 5).join(" ")}
                      </span>
                      <span
                        style={{
                          display: "block",
                          color: "black",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {item.description.split(" ").slice(5).join(" ")}
                      </span>
                    </h1> */}
                    <h1
                      className=" pt-5 px-4 py-4 carousel-des "
                      dangerouslySetInnerHTML={{
                        __html: item?.description,
                      }}
                    />
                  </div>
                  <div className="card-container">
                    <div className="card-content">
                      {/* <h4 className="card-title">Mission:</h4> */}
                      <h6 className="card-text">
                        {item?.vision || "NA"}
                      </h6>
                    </div>
                  </div>

                  <div className="bg-carousel-aim mt-5">
                    <div className="card-content">
                      {/* <h6 className="aim-title">Aim :</h6> */}
                      <h6 className="aim-content">
                      {item?.aim || "NA"}

                      </h6>
                    </div>
                  </div>
                </Col>

                <Col md={4}>
                  <img
                    className="d-block mt-5"
                    src={item?.headerImage}
                    alt={item.description}
                    style={{
                      height: "697px",
                      width: "427px",
                      objectFit: "contain",
                      borderRadius: "24px",
                    }}
                  />
                </Col>
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default CarouselComponent;
