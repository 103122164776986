import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components/tables";
import { UsersDataGridCols } from "./data-grid-cols";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";
import moment from "moment";
import Swal from "sweetalert2";

const UsersList = () => {
  const [name, setName] = useState("");
  const [usersData, setUsersData] = useState<any>([]);
  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const navigate = useNavigate();

  const handleCancel = () => {
    setName("");
    setFilteredUsers(usersData);
  };

  const handleSearch = () => {
    const filteredData = usersData.filter((user: any) => 
      user?.firstName?.toLowerCase().includes(name.toLowerCase())
    );
    setFilteredUsers(filteredData);
    setName('');
  };

  const handleCreateUser = () => {
    navigate("/admin/createuser");
  };

  useEffect(() => {
    getUsers();
  }, []);

  const deleteInfo = (userId: string) => {
    Swal.fire({
      title: "Are you sure you want to delete user",
      text: "Once deleted, you won't be able to recover this entry!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F5BE18",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          userId: userId,
        };
  
        AdminService.deleteUser(payload).then((res: any) => {
          if (res.status === "Success") {
            toast.success(res.message);
            getUsers(); // Refresh the data after deletion
          } else {
            toast.error(res.message);
          }
        });
      }
    });
  };

  const handleEditUser = (data: any) => {
    navigate("/admin/editUser", { state: data });
  };

  const getUsers = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getAllUserDetails(data).then((res: any) => {
      if (res.status === "Success") {
        const formattedData = res.data.map((item: any) => {
          return {
            ...item,
            dateOfJoining: moment(item.createdAt).format("YYYY-MM-DD"),
          };
        });
        setUsersData(formattedData);
        setFilteredUsers(formattedData); 
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
        <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <b>Users List</b>
                </label>
                <div className="d-flex">
                  <input
                    name="User Name"
                    onChange={(e: any) => setName(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={name}
                    placeholder="Enter the User Name"
                  />
                  <button
                    className="btn btn-primary rounded px-4 py-1"
                    onClick={() => handleSearch()}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="text-end">
                  <button
                    className="btn btn-success"
                    onClick={handleCreateUser}
                  >
                   Create User
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
            <DataTable
            tableData={filteredUsers}
            TableCols={UsersDataGridCols}
            handleDeleteUser={deleteInfo}
            handleEditUser={handleEditUser}
          />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersList;
