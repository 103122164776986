import React, { useState } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import Header from "../Header/Header";
import Footer from "../footer/Footer";
import { UserService } from "../../app/service/user.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import bannerBg from "../../assets/images/banner-guideless.png";


const EditorJoiningPage = () => {
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  // State to hold form values
  const [formData, setFormData] = useState<any>({
    editorType: "",
    editorName: "",
    editorQualification: "",
    editorDesignation: "",
    editorDepartment: "",
    universityName: "",
    country: "",
    cityState: "",
    editorEmail: "",
    editorPhone: "",
    editorFax: "",
    editorBiography: "",
    editorResearchInterest: "",
    editorPhoto: null,
  });

  // Handle form submission
  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      setValidated(true);

      try {
        const formDataToSend = new FormData();

        // Append each field
        Object.keys(formData).forEach((key) => {
          if (key === "editorPhoto" && formData[key]) {
            formDataToSend.append("editorPhoto", formData[key]); // Ensure the file is correctly appended
          } else {
            formDataToSend.append(
              key,
              formData[key as keyof typeof formData] as any
            );
          }
        });

        // Make the API call
        const res = await UserService.createEditorJoining(formDataToSend);

        if (res.status === "Fail") {
          toast.error(res.error);
        } else {
          setFormData({
            editorType: "",
            editorName: "",
            editorQualification: "",
            editorDesignation: "",
            editorDepartment: "",
            universityName: "",
            country: "",
            cityState: "",
            editorEmail: "",
            editorPhone: "",
            editorFax: "",
            editorBiography: "",
            editorResearchInterest: "",
            editorPhoto: null,
          });
          toast.success(res.message);
        }
      } catch (error) {
        console.error("Error making API call:", error);
      }
    }
  };

  // Handle field changes and update form data
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { id, value, files }: any = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [id]: files ? files[0] : value, // Handle file input separately
    }));
  };

  return (
    <div>
      <Header />
      {/* <div className="bg-dark pt-5 pb-5">
        <Container>
          <h3 className="pt-3 text-white text-left side-font">
          </h3>
        </Container>
      </div> */}
      <div
        className="d-flex align-items-center justify-content-center text-center text-warning"
        style={{
          backgroundImage: `url(${bannerBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "200px",
          width: "100%",
        }}
      >
        <h3 className="fw-bold text-white"> Editor Join Us</h3>
      </div>
      <Container className="pt-4 pb-4">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="editorType">
                <Form.Label>Editor Type *</Form.Label>
                <Form.Control
                  as="select"
                  value={formData.editorType}
                  onChange={handleChange}
                  required
                >
                  <option value="">--Editor Type--</option>
                  <option>Editor</option>
                  <option>Associate Editor</option>
                  <option>Assistant Editor</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select an editor type.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="editorName">
                <Form.Label>Editor Name *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Editor Name"
                  value={formData.editorName}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter editor name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="editorQualification">
                <Form.Label>Editor Qualification (Eg. MD, PhD) *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Editor Qualification"
                  value={formData.editorQualification}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter qualification.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="editorDesignation">
                <Form.Label>Editor Designation *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Eg: Professor, Associate Professor, etc."
                  value={formData.editorDesignation}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter designation.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="editorDepartment">
                <Form.Label>Editor Department *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Editor Department"
                  value={formData.editorDepartment}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter department.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="universityName">
                <Form.Label>University Name *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="University Name"
                  value={formData.universityName}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter university name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="country">
                <Form.Label>Country *</Form.Label>
                <Form.Control
                  as="select"
                  value={formData.country}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Country</option>
                  <option>USA</option>
                  <option>India</option>•••••••••••
                  <option>UK</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select a country.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="cityState">
                <Form.Label>City or State </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Eg: City or State Name"
                  value={formData.cityState}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="editorEmail">
                <Form.Label>Editor E-Mail *</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Editor E-Mail"
                  value={formData.editorEmail}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="editorPhone">
                <Form.Label>Editor Phone </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Editor Phone"
                  value={formData.editorPhone}
                  onChange={handleChange}
                />
                {/* <Form.Control.Feedback type="invalid">
                  Please enter a valid phone number.
                </Form.Control.Feedback> */}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="editorFax">
                <Form.Label>Editor Fax</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Editor Fax"
                  value={formData.editorFax}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="editorPhoto">
                <Form.Label>Editor Photo</Form.Label>
                <Form.Control type="file" onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Form.Group controlId="editorBiography">
                <Form.Label>Editor Biography (Optional)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Biography..."
                  value={formData.editorBiography}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Form.Group controlId="editorResearchInterest">
                <Form.Label>Editor Research Interest (Optional)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Research Interest..."
                  value={formData.editorResearchInterest}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3">
            <Form.Check required label="I'm not a robot" />
          </Form.Group>

          <Button type="submit" variant="success">
            Register Here
          </Button>
        </Form>
      </Container>
      <Footer />
    </div>
  );
};

export default EditorJoiningPage;
