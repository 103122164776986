import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components/tables";
import { contactUsGridCols } from "./data-grid-cols";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";
import Swal from "sweetalert2";

const ContactUsList = () => {
  const [searchName, setSearchName] = useState("");
  const [usersData, setUsersData] = useState<any>([]);
  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const navigate = useNavigate();

  const handleCancel = () => {
    setSearchName("");
    setFilteredUsers(usersData);
  };

  const handleSearch = () => {
    const filteredData = usersData.filter((user: any) =>
      user?.name?.toLowerCase().includes(searchName.toLowerCase())
    );
    setFilteredUsers(filteredData);
    setSearchName("");
  };

 

  useEffect(() => {
    getAllcontactus();
  }, []);

  const deleteContactusInfo = (contactUsId: string) => {
    Swal.fire({
      title: "Are you sure you want to delete Contact Info",
      text: "Once deleted, you won't be able to recover this entry!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F5BE18",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          contactUsId: contactUsId
        };

        AdminService.deleteContactUs(payload).then((res: any) => {
          if (res.status === "Success") {
            toast.success(res.message);
            getAllcontactus(); 
          } else {
            toast.error(res.message);
          }
        });
      }
    });
  };

  const handleEditUser = (data: any) => {
    navigate("/admin/editUser", { state: data });
  };

  const getAllcontactus = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getAllcontactus(data).then((res: any) => {
      if (res.status === "Success") {
        setUsersData(res.data);
        setFilteredUsers(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <b>Users List</b>
                </label>
                <div className="d-flex">
                  <input
                    name="Search Name"
                    onChange={(e: any) => setSearchName(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={searchName}
                    placeholder="Enter the Search Name"
                  />
                  <button
                    className="btn btn-primary rounded px-4 py-1"
                    onClick={() => handleSearch()}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                {/* <div className="text-end">
                  <button
                    className="btn btn-success"
                    onClick={handleCreateUser}
                  >
                    Create User
                  </button>
                </div> */}
              </div>
            </div>
            <div className="mt-5">
              <DataTable
                tableData={filteredUsers}
                TableCols={contactUsGridCols}
                deleteContactusInfo={deleteContactusInfo}
                handleEditUser={handleEditUser}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsList;
