import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/footer/Footer'
import CurrentIssue from '../../components/journals/currentIssue'

const CurrentIssuePage = () => {
    return (
        <div>
            <Header />
            <CurrentIssue />
            <Footer />
        </div>
    )
}

export default CurrentIssuePage