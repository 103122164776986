import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SubmissionPage from "../submissionPage/SubmissionPage";
import {
  faGlobe,
  faBook,
  faLock,
  faUsers,
  faGraduationCap,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

import aboutBg from "../../assets/images/about-bg.png";
import Rectangle380 from "../../assets/images/Rectangle 380.png";
import Rectangle381 from "../../assets/images/Rectangle 381.png";
import Rectangle382 from "../../assets/images/Rectangle 382.png";
import Rectangle383 from "../../assets/images/Rectangle 383.png";
import Rectangle384 from "../../assets/images/Rectangle 384.png";
import Rectangle385 from "../../assets/images/Rectangle 385.png";
import circleImage from "../../assets/images/uil_process.png";

const AboutUs: React.FC = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="text-dark">
      <div
        className="d-flex align-items-center justify-content-center text-center text-warning"
        style={{
          backgroundImage: `url(${aboutBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "200px",
          width: "100%",
        }}
      >
        <h3 className="fw-bold text-white">About Us</h3>
      </div>
      <div className="container p-5">
        <section className="row gy-4 align-items-center">
          <div className="col-lg-6 d-flex flex-column justify-content-center text-center text-lg-start">
            <h1 className="display-5 fw-bold text-warning">
              Welcome to SciencePhotons
            </h1>
            <p className="lead">
              At Science Photons, we aim to build a vibrant ecosystem where
              knowledge is freely shared, rigorously evaluated, and thoughtfully
              applied to improve human health and well-being.
            </p>
            <p className="lead">
              We are passionate about providing high-quality, open-access
              scientific content that pushes the boundaries of knowledge and
              fosters groundbreaking innovation. Our mission is to illuminate
              the path of scientific discovery by publishing pioneering research
              across various disciplines.
            </p>
          </div>

          <div className="col-lg-6">
            <div className="row row-cols-2 g-2">
              <div className="col">
                <img
                  src={Rectangle380}
                  alt="Science Image 1"
                  className="img-fluid rounded-4"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div className="col">
                <img
                  src={Rectangle381}
                  alt="Science Image 2"
                  className="img-fluid rounded-4"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div className="col">
                <img
                  src={Rectangle382}
                  alt="Science Image 3"
                  className="img-fluid rounded-4"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div className="col">
                <img
                  src={Rectangle383}
                  alt="Science Image 4"
                  className="img-fluid rounded-4"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <h4>Why publish with science photons ?</h4>
          <div className="row mt-5 mb-5">
            <div className="col-md-2">
              <div className="rounded-5 border-0 h-100">
                <div
                  className={`card text-white text-start`}
                  style={{ backgroundColor: "rgb(28, 69, 70)" ,height:"225px"}}
                >
                  <div className="card-body">
                    <h3 className="h6 fw-bold">Tailored APC:</h3>
                    <p className="small">
                      We offer flexible and tailored Article Processing Charges
                      (APC) to suit authors' needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1 mt-5">
              <img
                src={`${circleImage}`}
                alt="Placeholder"
                className="img-fluid rounded-4 mt-4"
              />
            </div>
            <div className="col-md-2">
              <div className="rounded-5 border-0 h-100">
                <div
                  className={`card text-white text-start`}
                  style={{ backgroundColor: "rgb(28, 69, 70)" ,height:"225px"}}
                >
                  <div className="card-body">
                    <h3 className="h6 fw-bold">Rapid Publication:</h3>
                    <p className="small">
                      Our streamlined review process ensures timely feedback and
                      quick publication.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1 mt-5">
              <img
                src={`${circleImage}`}
                alt="Placeholder"
                className="img-fluid rounded-4 mt-4"
              />
            </div>
            <div className="col-md-2">
              <div className="rounded-5 border-0 h-100">
                <div
                  className={`card text-white text-start`}
                  style={{ backgroundColor: "rgb(28, 69, 70)",height:"225px" }}
                >
                  <div className="card-body">
                    <h3 className="h6 fw-bold">Global Reach:</h3>
                    <p className="small">
                      Your work will be accessible to researchers, academics,
                      and practitioners worldwide, making a real impact in your
                      field.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1 mt-5">
              <img
                src={`${circleImage}`}
                alt="Placeholder"
                className="img-fluid rounded-4 mt-4"
              />
            </div>
            <div className="col-md-2">
              <div className="rounded-5 border-0 h-100">
                <div
                  className={`card text-white text-start`}
                  style={{ backgroundColor: "rgb(28, 69, 70)" ,height:"225px"}}
                >
                  <div className="card-body">
                    <h3 className="h6 fw-bold">Rigorous Peer Review:</h3>
                    <p className="small">
                      Each manuscript undergoes an in-depth, unbiased review by
                      experts to guarantee scientific excellence.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-1">
            <img
            src={`${circleImage}`}
            alt="Placeholder"
            className="img-fluid rounded-4"
          />
            </div> */}
          </div>
        </section>

        <section
          style={{ backgroundColor: "#1C4546" }}
          className=" text-white p-4 rounded-4 d-flex align-items-center justify-content-between flex-column flex-md-row my-5"
        >
          <div className="col-md-3 mb-3 mb-md-0">
            <img
              src={`${Rectangle384}`}
              alt="Placeholder"
              className="img-fluid rounded-4"
            />
          </div>
          <div className="text-center px-3">
            <h2 className="h4 fw-bold text-warning">Our Vision</h2>
            <p>
              We envision a world where scientific knowledge is freely shared
              and easily accessible, driving progress and innovation for the
              betterment of society.
            </p>
          </div>
          <div className="col-md-3 mt-3 mt-md-0">
            <img
              src={`${Rectangle385}`}
              alt="Placeholder"
              className="img-fluid rounded-4"
            />
          </div>
        </section>
        <section></section>

        <MissionSection />

        <section className="text-center my-5">
          <h2 className="h4 fw-bold text-start text-warning">Our Values:</h2>
          <div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-4">
            <ValueItem
              number="1"
              title="Integrity"
              description="We uphold the highest standards of ethical publishing practices."
            />
            <FontAwesomeIcon
              icon={faArrowRight}
              className="d-none d-md-inline text-warning fs-4"
              aria-hidden="true"
            />
            <ValueItem
              number="2"
              title="Innovation"
              description="We embrace new ideas and technologies to improve the dissemination of scientific knowledge."
            />
            <FontAwesomeIcon
              icon={faArrowRight}
              className="d-none d-md-inline text-warning fs-4"
              aria-hidden="true"
            />
            <ValueItem
              number="3"
              title="Inclusivity"
              description="We support diversity and inclusivity in all aspects of our work, ensuring that all voices are heard and valued."
            />
          </div>
        </section>
      </div>

      <section
        className="p-5 text-center"
        style={{ backgroundColor: "#F5E1D9" }}
      >
        <div className="container">
          <h2 className="display-5  fw-bold" style={{ color: "#095f60" }}>
            Join Us
          </h2>
          <p className="lead text-black">
            Whether you are a researcher looking to publish your work, a reader
            seeking the latest scientific discoveries, or a collaborator with
            innovative ideas, Science Photons welcomes you. Together, we can
            light the way to a brighter scientific future.
          </p>
          <p className="h3 fw-bold mt-4" style={{ color: "#F5BE18" }}>
            Thank you for being a part of our journey.
          </p>
        </div>
      </section>
      <div className="">
        <SubmissionPage />
      </div>
    </div>
  );
};

const MissionSection: React.FC = () => {
  return (
    <div className="py-4 text-center">
      <h2 className="text-warning h4 fw-bold mb-4 text-start">Our Mission</h2>
      <div className="d-flex justify-content-around flex-wrap align-items-start gap-3">
        <MissionCard
          title="Illuminate Authors' Work Globally:"
          description="We are dedicated to publishing groundbreaking research across various scientific disciplines, ensuring that valuable contributions receive the global attention they deserve."
          icon={<FontAwesomeIcon icon={faGlobe} className="fs-2" />}
          bgColor="#FFB901"
        />
        <MissionCard
          title="Publish Excellence:"
          description="We are committed to showcasing top-tier, peer-reviewed research that contributes significantly to scientific understanding."
          icon={<FontAwesomeIcon icon={faBook} className="fs-2" />}
          bgColor="#0A6AB3"
        />
        <MissionCard
          title="Foster Collaboration:"
          description="We provide a platform for researchers, scholars, and practitioners to share their findings and collaborate globally"
          icon={<FontAwesomeIcon icon={faUsers} className="fs-2" />}
          bgColor="#20C233"
        />
        <MissionCard
          title="Enhance Accessibility:"
          description="As an open-access journal, we ensure that all published research is freely available to everyone, removing barriers to knowledge and promoting education and awareness."
          icon={<FontAwesomeIcon icon={faLock} className="fs-2" />}
          bgColor="#E2642D"
        />
        <MissionCard
          title="Engage Readership:"
          description="We publish timely, credible, and engaging content that is pleasant to read."
          icon={<FontAwesomeIcon icon={faGraduationCap} className="fs-2" />}
          bgColor="#C862CC"
        />
      </div>
    </div>
  );
};

interface MissionCardProps {
  title: string;
  description: string;
  icon: JSX.Element;
  bgColor: string;
}

const MissionCard: React.FC<MissionCardProps> = ({
  title,
  description,
  icon,
  bgColor,
}) => (
  <div className="d-flex flex-column align-items-center rounded-5 border-0">
    <div
      className={`card ${bgColor} text-white text-start`}
      style={{ width: "220px", minHeight: "250px", backgroundColor: bgColor }}
    >
      <div className="card-body">
        <h3 className="h6 fw-bold">{title}</h3>
        <p className="small">{description}</p>
      </div>
    </div>
    <div className="mt-3">{icon}</div>
  </div>
);

interface ValueItemProps {
  number: string;
  title: string;
  description: string;
}

const ValueItem: React.FC<ValueItemProps> = ({
  number,
  title,
  description,
}) => (
  <div className="d-flex flex-column align-items-center w-100 text-center">
    <div
      className="rounded-circle bg-warning d-flex align-items-center justify-content-center mb-2"
      style={{ width: "80px", height: "80px" }}
    >
      <span className="text-white fs-4 fw-bold">{number}</span>
    </div>
    <h3 className="h6 fw-bold">{title}:</h3>
    <p className="text-muted small">{description}</p>
  </div>
);

export default AboutUs;
