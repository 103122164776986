import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import { AdminService } from "../../app/service/admin.service";

interface Journal {
  year: number;
  description: string;
  volume: string;
  issue: string;
  month: string;
  articlejournalName: string;
}

const Archive = () => {
  const { state } = useLocation();
  const [articleData, setArticleData] = useState<Journal[]>([]); // Typed array of Journal objects
  const navigate = useNavigate();
  const [jData, setJData] = useState<any>([]);

  useEffect(() => {
    scrollToTop();
    getJournalData();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const createSlug = (title: any) => {
    return title
      ?.toLowerCase()
      ?.replace(/[^a-z0-9\s-]/g, "")
      ?.trim()
      ?.replace(/\s+/g, "-");
  };

  const items = [
    {
      label: "Journal Home",
      path: `/journal-details/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    {
      label: "Aim and Scope",
      path: `/journalsAims/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    {
      label: "Editorial Board",
      path: `/editor-journal-details/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    {
      label: "Author Guidelines",
      path: `/author-guidelines/${createSlug(jData[0]?.journalTitle)}`,
    },
    // {
    //   label: "Special Issues",
    //   path: `/special-issues/${createSlug(jData[0]?.journalTitle)}`,
    //   state: state,
    // },
    {
      label: "Article In Press",
      path: `/article-in-press/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    {
      label: "Current Issue",
      path: `/current-issue/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    {
      label: "Archives",
      path: `/archives/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    // {
    //   label: "Open Access Policy",
    //   path: `/open-access/${createSlug(jData[0]?.journalTitle)}`,
    // },
    {
      label: "Submit Manuscript",
      path: `/submission-page-url/${createSlug(jData[0]?.journalTitle)}`,
    },
  ];

  useEffect(() => {
    getArticleData();
  }, []);

  const getArticleData = () => {
    const data = {
      articleId:
        state?.journal?.journalNameId ||
        state?.journalId ||
        state?.articlejournalName,
      currentIssues: true,
      previousIssues: true,
    };

    AdminService.getArticle(data).then((res) => {
      if (res.status === "Success" && res.data) {
        setArticleData(res.data);
      } else {
        console.log("err", res.message);
      }
    });
  };

  const getJournalData = () => {
    const data = {
      journalId:
        state?.journal?.journalId ||
        state?.articlejournalName ||
        state?.journal?.journalNameId,
    };
    AdminService.getJournalData(data).then((res) => {
      if (res.status === "Success") {
        setJData(res.data);
      } else {
        console.log("err", res.message);
      }
    });
  };

  const handleView = (description: string, journal: Journal) => {
    const { volume, issue, year, month, articlejournalName } = journal;
    const url = `/archiveData?journal=${articlejournalName}&v=${volume}&i=${issue}&y=${year}&m=${month}`;

    navigate(url, {
      state: {
        journalId: articlejournalName,
        description,
      },
    });
  };

  return (
    <div className="pb-3">
      <style>
        {`
          .scrollable-top::-webkit-scrollbar {
            width: 3px;
          }
          .scrollable-top::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 7px;
          }
          .scrollable-top::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 7px;
          }
          .scrollable-top::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        `}
      </style>
      <div
        className="bg-dark py-4 px-4 mt-5"
        style={{
          backgroundImage: `url(${jData[0]?.journalHeaderImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h4 className="text-white text-center">
          <b>{jData[0]?.journalTitle}</b>
        </h4>
        <h4 className="text-center text-white">
          <b>ISSN: {jData[0]?.ISSNNumber}</b>
        </h4>
      </div>
      <Container>
        <Row className="mt-3">
          <Col md={3}>
            <div>
              <img
                src={`${jData[0]?.journalImage}`}
                alt="Logo"
                className="mb-3 rounded h-100 mt-2"
                style={{ width: "212px", height: "281px" }}
              />
            </div>
            <div className="mb-3">
              <div
                className="scrollable-top border-2 border-black"
                style={{ maxHeight: "400px", overflowY: "auto" , maxWidth:'212px', width:'100%'}}
              >
                <h5 className="text-left">
                  <b>Journal Topics</b>
                </h5>
                <ul className="list-unstyled">
                  {jData[0]?.topics.map((topic: any, index: number) => (
                    <li key={index} className="py-1">
                      {topic}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <h5 className="text-black text-center mb-5 mt-2 fw-bold">Archive Page</h5>
            {articleData.length > 0 ? (
              <>
  {Array.from(new Set(articleData.map((journal) => journal.year)))
    .sort((a, b) => b - a)
    .map((year) => (
      <div key={year} className="mb-4 d-flex justify-content-center">
        <Card
          className="shadow-sm border-0"
          style={{
            width: "320px",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              backgroundColor: "#1C4546",
              color: "white",
              padding: "10px",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.25em",
            }}
          >
            {year}
          </div>
          <Card.Body>
            <div
              className="d-flex flex-column align-items-center overflow-auto"
              style={{
                maxHeight: "250px",
                padding: "10px",
                borderRadius: "8px",
                width: "100%",
                position: "relative",
              }}
            >
              {Array.from(
                new Set(
                  articleData
                    .filter((journal) => journal.year === year)
                    .map((journal) => journal.description) // Use description as a unique identifier
                )
              ).map((description, index) => {
                const journal = articleData.find(
                  (j) => j.year === year && j.description === description
                );

                // Check if journal is defined before rendering the button
                return (
                  journal && (
                    <Button
                      key={index}
                      variant="light"
                      className="mb-2 text-dark"
                      style={{
                        textAlign: "center",
                        padding: "10px 15px",
                        borderRadius: "5px",
                        fontWeight: "bold",
                        boxShadow:
                        index === 2 ? "inset 0px 0px 5px 2px rgba(0,0,0,0.2)" : "none",
                        backgroundColor: index === 2 ? "#FFB901" : "#FFE492",
                        width: "250px",
                      }}
                      onClick={() => handleView(journal.description, journal)}
                    >
                      {description || "Volume 1 Issue 1"}
                    </Button>
                  )
                );
              })}
            </div>
          </Card.Body>
        </Card>
      </div>
    ))}
</>

            ) : (
              <p>No Archive Data found.</p>
            )}
          </Col>
          <Col md={3}>
            <div
              className="scrollable-top pt-2 pb-2 ps-4 pe-4 h-100"
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                border: "none",
              }}
            >
              <h5 className="fw-bold">Index</h5>
              {items.map((item, index) => (
                <NavLink
                  to={item.path}
                  state={item.state} // Pass the entire state
                  style={({ isActive }) => ({
                    color: isActive ? "#FFB901" : "black",
                    borderLeft: isActive ? "4px solid yellow" : "none",
                    paddingLeft: isActive ? "8px" : "12px", // Adjust padding to align text
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                  })}
                  key={index}
                >
                  <div>
                    <p className="mt-2">{item.label}</p>
                  </div>
                </NavLink>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
      <br />
    </div>
  );
};

export default Archive;
