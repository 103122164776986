import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { roles } from "../../../config";
import { useNavigate, useLocation } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";
import Select from "react-select";

const EditUser = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [formData, setFormData] = useState<any>({
        email: "",
        firstName: "",
        lastName: "",
        role: "",
        userName: "",
        password: "",
        confirmPassword: "",
        userJournalName: '',
        dateOfJoining: ''
    });

    const [data, setData] = useState<any>([]);
    const [errors, setErrors] = useState<any>({});

    const handleInput = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevState: any) => ({
            ...prevState,
            [name]: name === "courseName" ? value.split(",") : value,
        }));
        setErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    useEffect(() => {
        if (state) {
            setFormData({
                email: state.email || "",
                firstName: state.firstName || "",
                lastName: state.lastName || "",
                role: state.role?.roleName || "",
                userName: state.userName || "",
                password: state.password || '',
                confirmPassword: "",
                userJournalName: formData?.userJournalName || '',
                dateOfJoining: state.dateOfJoining ? state.dateOfJoining.split('T')[0] : '', // Format date to 'YYYY-MM-DD'
            });
        }
        getJournalNames();
    }, [state]);

    const getJournalNames = async () => {
        try {
            const response = await AdminService.allJournalNames("");
            setData(response.data);
        } catch (error) {
            console.error("Error fetching journal names:", error);
        }
    };

    const validate = () => {
        const newErrors: any = {};

        if (!formData.email) {
            newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Invalid email format";
        }

        if (!formData.firstName) {
            newErrors.firstName = "First Name is required";
        }

        if (!formData.lastName) {
            newErrors.lastName = "Last Name is required";
        }

        if (!formData.role) {
            newErrors.role = "Role is required";
        }
        if (!formData.userJournalName) {
            newErrors.userJournalName = "Journal Name is required";
        }

        if (!formData.userName) {
            newErrors.userName = "User Name is required";
        } 

        if (!formData.password) {
            newErrors.password = "Password is required";
        } else if (formData.password.length < 6) {
            newErrors.password = "Password must be at least 6 characters";
        }

        if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match";
        }

        if (!formData.dateOfJoining) {
            newErrors.dateOfJoining = "Date of Joining is required"; // Add validation for dateOfJoining
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleRegister = (e: any) => {
        e.preventDefault();
       
        formData.userId = state?._id;

        if (validate()) {
            AdminService.updateUser(formData).then((res) => {
                if (res.status === "Fail") {
                    toast.error(res.error);
                } else {
                    toast.success(res.message);
                    navigate("/admin/userList");
                }
            });
        }
    };

    const handleCancel = () => {
        navigate("/admin/userList");
    };

    const handleSelectChange = (selectedOption: any) => {
        setFormData((prevData: any) => ({
            ...prevData,
            userJournalName: selectedOption ? selectedOption.id : '',
        }));
    };

    const journalOptions = data?.map((type: any) => ({
        value: type.journalName,
        label: type.journalName,
        id: type._id,
    }));

    return (
        <div className="container-fluid">
            <div className="row">
                <Header />
            </div>
            <div className="row">
                <div className="col-md-2 h-100 px-0">
                    <Sidebar />
                </div>
                <div className="col-md-10 content-scroll content-bg px-3 py-3">
                    <h4 style={{ textAlign: "left" }}>
                        <b>Edit User</b>
                    </h4>
                    <form>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mt-3">
                                    <label className="register-font">First Name *</label>
                                    <input
                                        type="text"
                                        className={`form-control mt-2 ${errors.firstName ? "is-invalid" : ""}`}
                                        placeholder="First Name"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInput}
                                    />
                                    {errors.firstName && (
                                        <div className="invalid-feedback">{errors.firstName}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mt-3">
                                    <label className="register-font">Last Name *</label>
                                    <input
                                        type="text"
                                        className={`form-control mt-2 ${errors.lastName ? "is-invalid" : ""}`}
                                        placeholder="Last Name"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInput}
                                    />
                                    {errors.lastName && (
                                        <div className="invalid-feedback">{errors.lastName}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mt-3">
                                    <label className="register-font">Email *</label>
                                    <input
                                        type="text"
                                        className={`form-control mt-2 ${errors.email ? "is-invalid" : ""}`}
                                        placeholder="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInput}
                                    />
                                    {errors.email && (
                                        <div className="invalid-feedback">{errors.email}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mt-3">
                                    <label className="register-font">User Name *</label>
                                    <input
                                        type="text"
                                        className={`form-control mt-2 ${errors.userName ? "is-invalid" : ""}`}
                                        placeholder="User Name"
                                        name="userName"
                                        value={formData.userName}
                                        onChange={handleInput}
                                    />
                                    {errors.userName && (
                                        <div className="invalid-feedback">{errors.userName}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mt-3">
                                    <label className="register-font">Journal Title *</label>
                                    <Select
                                        options={journalOptions}
                                        onChange={handleSelectChange}
                                        isClearable
                                        placeholder="Select Journal Name"
                                        classNamePrefix="react-select"
                                        className={errors.userJournalName ? "is-invalid" : ""}
                                    />
                                    {errors.userJournalName && (
                                        <div className="text-danger">{errors.userJournalName}</div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group mt-3">
                                    <label className="register-font">Select Role *</label>
                                    <select
                                        className={`form-control mt-2 ${errors.role ? "is-invalid" : ""}`}
                                        name="role"
                                        value={formData.role}
                                        onChange={handleInput}
                                    >
                                        <option value="">Select Role</option>
                                        {roles.map((role, index) => (
                                            <option value={role.value} key={index}>
                                                {role.name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.role && (
                                        <div className="invalid-feedback">{errors.role}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mt-3">
                                    <label className="register-font">Date Of Joining *</label>
                                    <input
                                        type="date"
                                        className={`form-control mt-2 ${errors.dateOfJoining ? "is-invalid" : ""}`}
                                        name="dateOfJoining"
                                        value={formData.dateOfJoining} // Ensure value is controlled
                                        onChange={handleInput}
                                    />
                                    {errors.dateOfJoining && (
                                        <div className="invalid-feedback">{errors.dateOfJoining}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mt-3">
                                    <label className="register-font">Password *</label>
                                    <input
                                        type="password"
                                        className={`form-control mt-2 ${errors.password ? "is-invalid" : ""}`}
                                        placeholder="Password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleInput}
                                    />
                                    {errors.password && (
                                        <div className="invalid-feedback">{errors.password}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mt-3">
                                    <label className="register-font">Confirm Password *</label>
                                    <input
                                        type="password"
                                        className={`form-control mt-2 ${errors.confirmPassword ? "is-invalid" : ""}`}
                                        placeholder="Confirm Password"
                                        name="confirmPassword"
                                        value={formData.confirmPassword}
                                        onChange={handleInput}
                                    />
                                    {errors.confirmPassword && (
                                        <div className="invalid-feedback">{errors.confirmPassword}</div>
                                    )}
                                </div>
                            </div>
                    </div>
                        <div className="mt-3">
                            <button
                                className="btn btn-primary"
                                onClick={handleRegister}
                            >
                                Update User
                            </button>
                            <button
                                className="btn btn-secondary ms-2"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditUser;
