import { DataTableCol } from "../../../components/tables/types";

export const contactUsGridCols: DataTableCol[] = [
    {
        title: 'S.no',
        control: 'serialNo',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Name',
        control: 'name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Email',
        control: 'email',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Subject',
        control: 'subject',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Message',
        control: 'message',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: false,
        isDelete: true,
        canShowColumn: true
    },
];