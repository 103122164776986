import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";

const CreateJournal = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<any>({
    journalShortCode: "",
    journalName: "",
  });

  const [errors, setErrors] = useState<any>({});

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors: any = {};

    if (!formData.journalName) {
      newErrors.journalName = "Journal Name is required";
    }

    if (!formData.journalShortCode) {
      newErrors.journalShortCode = "Journal Short Code is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = (e: any) => {
    e.preventDefault();
    const loginData: any = sessionStorage.getItem("loginData");
    const data = JSON.parse(loginData);

    formData.userName = data.email;

    if (validate()) {
      AdminService.createJournalName(formData).then((res) => {
        if (res.status === "Fail") {
          toast.error(res.error);
        } else {
          toast.success(res.message);
          navigate("/admin/journalsList");
        }
      });
    }
  };

  const handleCancel = () => {
    navigate("/admin/journalsList");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Add New Journal</b>
          </h4>
          <form>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Journal Name *</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.journalName ? "is-invalid" : ""
                    }`}
                    placeholder="Journal Name"
                    name="journalName"
                    value={formData.journalName}
                    onChange={handleInput}
                  />
                  {errors.journalName && (
                    <div className="invalid-feedback">{errors.journalName}</div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">journal Short Code</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.journalShortCode ? "is-invalid" : ""
                    }`}
                    placeholder="Journal Short Code"
                    name="journalShortCode"
                    value={formData.journalShortCode}
                    onChange={handleInput}
                  />
                  {errors.journalShortCode && (
                    <div className="invalid-feedback">{errors.journalShortCode}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <button
                type="submit"
                className="btn btn-primary me-2"
                onClick={handleRegister}
              >
                Create
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateJournal;
