import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { AppProvider } from "./components/context/AppContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { RootNavigation } from "./routes";

function App() {
  return (
    <>
      <AppProvider>
        <div className="App Eduexpose " style={{ scrollbarWidth: "none" }}>
          <ToastContainer />
          <BrowserRouter>
            <RootNavigation />
          </BrowserRouter>
        </div>
      </AppProvider>
    </>
  );
}

export default App;
