import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import logoImg from "../../assets/images/logo.jpg";
import EditorJournalsList from "./editorJournalsList";
import { AdminService } from "../../app/service/admin.service";
import moment from "moment";
import SubmissionPage from "../submissionPage/SubmissionPage";
import { FaLockOpen } from "react-icons/fa";
import { IoMdPerson } from "react-icons/io";

const ArticleInPress = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [articleData, setArticleData] = useState([]);
  const [jData, setJData] = useState<any>([]);

  useEffect(() => {
    scrollToTop();
    getJournalData();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const createSlug = (title: any) => {
    return title
      ?.toLowerCase() // Convert to lowercase
      ?.replace(/[^a-z0-9\s-]/g, "") // Remove any special characters
      ?.trim() // Remove leading and trailing whitespace
      ?.replace(/\s+/g, "-"); // Replace spaces with hyphens
  };

  const items = [
    {
      label: "Journal Home",
      path: `/journal-details/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    {
      label: "Aim and Scope",
      path: `/journalsAims/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    {
      label: "Editorial Board",
      path: `/editor-journal-details/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    {
      label: "Author Guidelines",
      path: `/author-guidelines/${createSlug(jData[0]?.journalTitle)}`,
    },
    // {
    //   label: "Special Issues",
    //   path: `/special-issues/${createSlug(jData[0]?.journalTitle)}`,
    //   state: state,
    // },
    {
      label: "Article In Press",
      path: `/article-in-press/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    {
      label: "Current Issue",
      path: `/current-issue/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    {
      label: "Archives",
      path: `/archives/${createSlug(jData[0]?.journalTitle)}`,
      state: state,
    },
    // {
    //   label: "Open Access Policy",
    //   path: `/open-access/${createSlug(jData[0]?.journalTitle)}`,
    // },
    {
      label: "Submit Manuscript",
      path: `/submission-page-url/${createSlug(jData[0]?.journalTitle)}`,
    },
  ];
  const topics = [
    "Neonatal care",
    "Pediatric infectious diseases",
    "Pediatric oncology",
    "Pediatric cardiology",
    "Pediatric endocrinology",
    "Pediatric neurology",
    "Pediatric surgery",
    "Pediatric respiratory health",
    "Growth and developmental disorders",
    "Adolescent health",
    "Pediatric mental health",
    "Pediatric nutrition and obesity",
    "Pediatric emergency care",
    "Preventive pediatrics and vaccinations",
    "Pediatric immunology and allergies",
    "Pediatric genetics",
    "Pediatric dermatology",
    "Pediatric nephrology",
    "Pediatric rheumatology",
    "Pediatric critical care",
    "Child and adolescent healthcare policy and practice",
  ];

  useEffect(() => {
    getArticleData();
  }, []);

  const getArticleData = () => {
    const data = {
      articleId:
        state?.journal?.journalNameId ||
        state?.journalId ||
        state?.articlejournalName,
      currentIssues: false,
      previousIssues: false,
    };

    AdminService.getArticle(data).then((res) => {
      if (res.status === "Success" && res.data) {
        setArticleData(res.data);
      } else {
        console.log("err", res.message);
      }
    });
  };

  const getJournalData = () => {
    const data = {
      journalId:
        state?.journal?.journalNameId ||
        state?.journalId ||
        state?.articlejournalName,
    };
    AdminService.getJournalData(data).then((res) => {
      if (res.status === "Success") {
        setJData(res.data);
      } else {
        console.log("err", res.message);
      }
    });
  };

  const handleAbstract = (item: any) => {
    let abstractId = item?._id;
    navigate(`/abstract/${abstractId}`, { state: item });
  };

  return (
    <div className="pb-3">
      <style>
        {`
          .scrollable-top::-webkit-scrollbar {
            width: 3px;
          }
          .scrollable-top::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 7px;
          }
          .scrollable-top::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 7px;
          }
          .scrollable-top::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        `}
      </style>
      <div
        className="bg-dark py-4 px-4"
        style={{
          backgroundImage: `url(${jData[0]?.journalHeaderImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h4 className="text-white text-center">
          <b>{jData[0]?.journalTitle}</b>
        </h4>
        <h4 className="text-center text-white">
          <b>ISSN: {jData[0]?.ISSNNumber}</b>
        </h4>
      </div>

      <Container>
        <Row className="mt-3">
          <Col md={3}>
            <div>
              <img
                src={`${jData[0]?.journalImage}`}
                alt="Logo"
                className="mb-3 rounded h-100"
                style={{ width:'212px', height:'281px'}}
              />
            </div>
            <div className="mb-3">
              <div
                className="scrollable-top border-2 border-black"
                style={{ maxHeight: "400px", overflowY: "auto", maxWidth:'212px', width:'100%' }}
              >
                <h5 className="text-left">
                  <b>Journal Topics</b>
                </h5>
                <ul className="list-unstyled">
                  {jData[0]?.topics.map((topic: any, index: any) => (
                    <li key={index} className="py-1">
                      {topic}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>

          <Col md={6}>
            <h5 className="text-left">Articles Inpress</h5>
            <Row className="pb-3 pt-3">
              {articleData.length > 0 ? (
                articleData.map((journal: any) => (
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    key={journal._id}
                    className="d-flex align-items-stretch mb-4"
                  >
                    <Card className="shadow-lg border-0 h-100 w-100">
                      <div
                        className="article-info d-flex flex-column flex-lg-row align-items-center justify-content-lg-between justify-content-center w-100 p-3"
                        style={{ gap: "0.5rem",backgroundColor:"rgba(251, 247, 234, 1)" }}
                      >
                        <div
                          className="fw-bold article-des text-truncate"
                          style={{
                            color: "black",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            paddingRight: "0.5rem",
                          }}
                        >
                          {journal.articleType}
                        </div>

                        <div
                          className="article-open d-flex justify-content-center align-items-center w-100 w-lg-auto p-lg-0 px-3"
                          style={{
                            color: "#666",
                            fontSize: "0.85rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "155px",
                          }}
                        >
                          <FaLockOpen className="me-1" />
                          <span className="article-open-text text-truncate">
                            Open Access
                          </span>
                        </div>
                      </div>

                      <Card.Body className="d-flex flex-column">
                        <Card.Title
                          className="mb-2"
                          style={{
                            fontSize: "1.25rem",
                            color: "#000",
                            fontWeight: "bold",
                          }}
                        >
                          {journal.title.length > 100
                            ? `${journal.title.substring(0, 100)}...`
                            : journal.title}
                        </Card.Title>

                        <Card.Text
                          className="text-muted mb-3"
                          style={{ fontSize: "0.95rem", color: "#555" }}
                        >
                          {journal.doiNumber || "NA"}
                        </Card.Text>

                        <div
                          className="d-flex align-items-center mb-3"
                          style={{ fontSize: "0.8rem", color: "#555" }}
                        >
                          <IoMdPerson
                            className="me-2"
                            style={{ fontSize: "22px" }}
                          />
                          {journal.authorNames || "Unknown Author"}
                        </div>

                        <div className="mt-auto">
                          <div className="d-flex flex-column flex-md-row gap-2">
                            <Button
                              className="article-abstract-btn w-25 w-md-auto"
                              style={{
                                backgroundColor: "#0f766e",
                                color: "white",
                                border: "none",
                                fontSize: "0.85rem",
                                padding: "8px 12px",
                              }}
                              onClick={() => handleAbstract(journal)}
                            >
                              Abstract
                            </Button>
                            {journal.photo && (
                              <Button
                                className="button-color article-abstract-btn w-25 w-md-auto"
                                style={{
                                  backgroundColor: "#095c4f",
                                  color: "white",
                                  border: "none",
                                  fontSize: "0.85rem",
                                  padding: "8px 12px",
                                }}
                                onClick={() =>
                                  window.open(journal.photo, "_blank")
                                }
                              >
                                PDF
                              </Button>
                            )}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              ) : (
                <p>No articles found.</p>
              )}
            </Row>
          </Col>
          <Col md={3}>
          <div
              className="scrollable-top pt-2 pb-2 ps-4 pe-4 h-100"
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                border: "none",
              }}
            >
              <h5 className="fw-bold">Index</h5>
              {items.map((item, index) => (
                <NavLink
                  to={item.path}
                  state={item.state} // Pass the entire state
                  style={({ isActive }) => ({
                    color: isActive ? "#FFB901" : "black",
                    borderLeft: isActive ? "4px solid yellow" : "none",
                    paddingLeft: isActive ? "8px" : "12px", // Adjust padding to align text
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                  })}
                  key={index}
                >
                  <div>
                    <p className="mt-2">{item.label}</p>
                  </div>
                </NavLink>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
      <br />
    </div>
  );
};

export default ArticleInPress;
