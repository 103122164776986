import { ROLES } from "../../app/utility/app-codes";
import {Header} from "./dashboardHeader";
import UsersList from "../adminDashboard/user/UsersList";
import { Sidebar } from "./sidebar";
import AdminDashboard from "../adminDashboard/dashboard/AdminDashboard";

export const Dashboard = () => {
    const userdata: any = sessionStorage.getItem("loginData");
    const userRole = JSON.parse(userdata);
    return (
      <div className="container-fluid ">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2  px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg">
          <AdminDashboard />
            {/* {ROLES.Admin === userRole?.role?.roleName && <UsersList />} */}
            {/* {ROLES.User === userRole?.role?.roleName && <UserDashboard />} */}
            {/* {ROLES.Support === userRole?.role?.roleName && <ManageCourseList />} */}
          </div>
        </div>
      </div>
    );
  };