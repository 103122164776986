import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";
import Select from "react-select";
import { Form, Button } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CreateJournalData = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [formData, setFormData] = useState<any>({
    journalTitle: "",
    journalNameId: "",
    ISSNNumber: "",
    journalImage: null,
    journalHeaderImage: null,
    topics: [""],
    description: '',
    jAimScope:''
  });

  const [errors, setErrors] = useState<any>({});

  // Fetch Journal Names for Select Dropdown
  useEffect(() => {
    getJournalNames();
  }, []);

  const getJournalNames = async () => {
    try {
      const response = await AdminService.allJournalNames("");
      setData(response.data);
    } catch (error) {
      console.error("Error fetching journal names:", error);
    }
  };

  const handleInput = (e: any, index?: any, selectedOption?: any) => {
    if (selectedOption) {
      const { value, id } = selectedOption;
      setFormData((prevState: any) => ({
        ...prevState,
        journalTitle: value,
        journalNameId: id,
      }));
    } else if (e.target.type === "file") {
      const { name, files } = e.target;
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: files[0],
      }));
    } else if (e.target.name === "topics") {
      const newTopics = [...formData.topics]; // Use simple array
      newTopics[index] = e.target.value; // Update the specific index
      setFormData((prevState:any) => ({
        ...prevState,
        topics: newTopics,
      }));
    } 
     else {
      const { name, value } = e.target;
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [e?.target?.name || selectedOption?.name]: "",
    }));
  };

  const addTopic = () => {
    setFormData((prevState:any) => ({
      ...prevState,
      topics: [...prevState.topics, ""], // Initialize with an empty string
    }));
  };

  // Remove a topic
  const removeTopic = (index:any) => {
    const newTopics = [...formData.topics];
    newTopics.splice(index, 1);
    setFormData((prevState:any) => ({
      ...prevState,
      topics: newTopics,
    }));
  };



  // Validate the form
  const validate = () => {
    const newErrors: any = {};

    if (!formData.journalTitle) {
      newErrors.journalTitle = "Journal Title is required";
    }

    if (!formData.ISSNNumber) {
      newErrors.ISSNNumber = "ISSN Number is required";
    }

    if (!formData.journalImage) {
      newErrors.journalImage = "Journal Image is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle Form Submission
  const handleRegister = (e:any) => {
    e.preventDefault();
    const loginData:any = sessionStorage.getItem("loginData");
    const data = JSON.parse(loginData);
    formData.userName = data.email;

    if (validate()) {
      const formDataToSubmit = new FormData();
      Object.keys(formData).forEach((key) => {
        if (Array.isArray(formData[key])) {
          if (key === "topics") {
            formData[key].forEach((topic:any) => {
              formDataToSubmit.append("topics[]", topic); // Append each topic string
            });
          } 
        } else {
          formDataToSubmit.append(key, formData[key]);
        }
      });

      AdminService.createJournalData(formDataToSubmit).then((res) => {
        if (res.status === "Fail") {
          toast.error(res.error);
        } else {
          toast.success(res.message);
          navigate("/admin/journalsDataList");
        }
      });
    }
  };

  const handleCancel = () => {
    navigate("/admin/journalsDataList");
  };

  // Map journal names to options for Select
  const journalOptions = data?.map((type: any) => ({
    value: type.journalName,
    label: type.journalName,
    id: type._id,
  }));

  const handleEditorChange = (event: any, editor: any) => {
    const data = editor.getData();
    setFormData({
      ...formData,
      jAimScope: data,
    });
  };
  const handledescriptionChange = (event: any, editor: any) => {
    const data = editor.getData();
    setFormData({
      ...formData,
      description: data,
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Add New Journal</b>
          </h4>
          <Form>
            {/* Journal Title */}
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="journalTitle" className="mt-3">
                  <Form.Label>Journal Title *</Form.Label>
                  <Select
                    options={journalOptions}
                    name="journalTitle"
                    placeholder="Select Journal"
                    onChange={(selectedOption: any) =>
                      handleInput(undefined, undefined, {
                        value: selectedOption.value,
                        id: selectedOption.id,
                        name: "journalTitle",
                      })
                    }
                  />
                  {errors.journalTitle && (
                    <div className="text-danger">{errors.journalTitle}</div>
                  )}
                </Form.Group>
              </div>

              {/* ISSN Number */}
              <div className="col-md-12">
                <Form.Group controlId="ISSNNumber" className="mt-3">
                  <Form.Label>ISSN Number *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="ISSN Number"
                    name="ISSNNumber"
                    value={formData.ISSNNumber}
                    onChange={handleInput}
                    className={errors.ISSNNumber ? "is-invalid" : ""}
                  />
                  {errors.ISSNNumber && (
                    <div className="invalid-feedback">{errors.ISSNNumber}</div>
                  )}
                </Form.Group>
              </div>

              {/* Journal Descriptions */}
             

              {/* Journal Descriptions */}
              <Form.Group className="mt-3">
                  <Form.Label>Descriptions</Form.Label>
                  <div className="custom-editor-height">
                  <CKEditor
                    editor={ClassicEditor}
                    data={formData.description}
                    onChange={handledescriptionChange}
                  
                  />
                  </div>
                  {errors.jAimScope && (
                    <div className="invalid-feedback d-block">
                      {errors.jAimScope}
                    </div>
                  )}
                </Form.Group>

              <Form.Group className="mt-3">
                  <Form.Label>Aim to Scope</Form.Label>
                  <div className="custom-editor-height">
                  <CKEditor
                    editor={ClassicEditor}
                    data={formData.jAimScope}
                    onChange={handleEditorChange}
                  
                  />
                  </div>
                  {errors.jAimScope && (
                    <div className="invalid-feedback d-block">
                      {errors.jAimScope}
                    </div>
                  )}
                </Form.Group>

              {/* Journal Image */}
              <div className="col-md-12">
                <Form.Group controlId="journalImage" className="mt-3">
                  <Form.Label>Journal Image *</Form.Label>
                  <Form.Control
                    type="file"
                    name="journalImage"
                    onChange={handleInput}
                    className={errors.journalImage ? "is-invalid" : ""}
                  />
                  {errors.journalImage && (
                    <div className="invalid-feedback">
                      {errors.journalImage}
                    </div>
                  )}
                </Form.Group>
              </div>

              {/* Journal Header Image */}
              <div className="col-md-12">
                <Form.Group controlId="journalHeaderImage" className="mt-3">
                  <Form.Label>Journal Header Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="journalHeaderImage"
                    onChange={handleInput}
                  />
                </Form.Group>
              </div>

              {/* Journal Topics */}
              <div className="col-md-12">
                <p className="mt-4">Topics</p>
                {formData.topics.map((topic:any, index:any) => (
                  <div key={index} className="d-flex mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Topic"
                      name="topics"
                      value={topic}
                      onChange={(e) => handleInput(e, index)}
                    />
                    <Button
                      variant="danger"
                      onClick={() => removeTopic(index)}
                      className="ms-2"
                    >
                      Remove
                    </Button>
                  </div>
                ))}
                <Button variant="primary" onClick={addTopic}>
                  Add Topic
                </Button>
              </div>

              {/* Submit Button */}
              <div className="col-md-12">
                <Button
                  variant="primary"
                  className="mt-3 "
                  onClick={handleRegister}
                >
                  Submit
                </Button>
                <Button
                  variant="secondary"
                  className="mt-3 ms-4 "
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CreateJournalData;
