import React from "react";
import SubmissionPage from "../submissionPage/SubmissionPage";
import ContactInformation from "../contactInformation/ContactInfo";
import bannerBg from "../../assets/images/banner-guideless.png";

const ReviewProcess = () => {
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center text-center text-warning"
        style={{
          backgroundImage: `url(${bannerBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "200px",
          width: "100%",
        }}
      >
        <h3 className="fw-bold text-white">
          {" "}
          Review Process at SciencePhotons
        </h3>
      </div>
      <div className="container">
        <p className='pt-5'>
          At <strong>SciencePhotons</strong>, we are committed to delivering a transparent, fair,
          and rigorous review process to ensure that only the highest-quality
          research is published. Our peer review model guarantees that each
          manuscript is evaluated thoroughly, maintaining the integrity and
          scientific accuracy that researchers, practitioners, and academics
          rely on.
        </p>

        <section style={{padding: '2rem 0' }}>
      <div className="container">
        <div
          className="p-5"
          style={{
            backgroundColor: '#FFFDF7',
            boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.16)',
            borderRadius: '8px',
          }}
        >
          <h4 className=" mb-4">Steps of the Peer Review Process</h4>

          <ol className="list-unstyled">
            <li>
              <h6 className="side-font">1. Manuscript Submission</h6>
              <p>
                Authors submit their manuscripts through our user-friendly online submission system. Each submission is acknowledged promptly, and our team begins the review process immediately to ensure timely feedback.
              </p>
            </li>

            <li>
              <h6 className="side-font">2. Initial Editorial Review</h6>
              <p>
                Once submitted, the manuscript undergoes an initial review by the editorial team to assess its alignment with the journal’s aims, scope, and submission guidelines. During this phase, we evaluate the manuscript’s originality, relevance, and overall quality. Manuscripts that do not meet the required standards or fall outside the journal's scope may be rejected at this stage.
              </p>
              <p>
                <strong>Outcome:</strong> Manuscripts either proceed to peer review or are returned to the author if deemed unsuitable for further consideration.
              </p>
            </li>

            <li>
              <h6 className="side-font">3. Double-Blind Peer Review</h6>
              <p>
                If the manuscript passes the initial review, it is sent to expert reviewers in the relevant field. We follow a double-blind review process, ensuring anonymity for both authors and reviewers, thus promoting objective and unbiased feedback.
              </p>
              <ul className="mb-2">
                <li><strong>Scientific Soundness:</strong> Is the research well-conducted, methodologically sound, and credible?</li>
                <li><strong>Originality:</strong> Does the manuscript offer new insights or findings?</li>
                <li><strong>Relevance:</strong> Is the research applicable to the journal’s field and the broader scientific community?</li>
              </ul>
            </li>

            <li>
              <h6 className="side-font">4. Reviewer Feedback</h6>
              <p>
                After carefully evaluating the manuscript, reviewers provide detailed feedback that highlights strengths, areas for improvement, and any critical revisions required. They also submit their recommendations to the editor, which may include:
              </p>
              <ul className="mb-2">
                <li>Accept as is</li>
                <li>Minor revisions required</li>
                <li>Major revisions required</li>
                <li>Reject</li>
              </ul>
            </li>

            <li>
              <h6 className="side-font">5. Editorial Decision</h6>
              <p>
                Based on the reviewers’ comments and recommendations, the editorial team makes one of the following decisions:
              </p>
              <ul className="mb-2">
                <li><strong>Manuscript Accepted:</strong> If the manuscript meets the standards and no further revisions are required, it moves directly to production.</li>
                <li><strong>Revise and Resubmit:</strong> Authors are invited to address the reviewers’ comments. This stage may involve minor or major revisions, depending on the feedback.</li>
                <li><strong>Manuscript Rejected:</strong> If the manuscript does not meet the journal’s scientific criteria or lacks novelty, it may be rejected.</li>
              </ul>
            </li>

            <li>
              <h6 className="side-font">6. Author Revisions (if applicable)</h6>
              <p>
                Authors are given the opportunity to revise their manuscript in response to reviewer feedback. We value transparency and fairness, so revised manuscripts are often sent back to the original reviewers for re-evaluation to ensure that all concerns have been adequately addressed.
              </p>
              <p>
                <strong>Revise and Resubmit:</strong> Authors are encouraged to respond to all reviewer comments thoughtfully and resubmit their updated manuscript for further consideration.
              </p>
            </li>

            <li>
              <h6 className="side-font">7. Final Decision</h6>
              <p>
                Upon receiving the revised manuscript, the editor reviews the changes and may consult the reviewers again. A final decision is made, which could be:
              </p>
              <ul className="mb-2">
                <li><strong>Manuscript Accepted:</strong> The revised manuscript meets all requirements and is approved for publication.</li>
                <li><strong>Manuscript Rejected:</strong> Despite revisions, the manuscript does not meet the journal’s publication standards.</li>
              </ul>
            </li>

            <li>
              <h6 className="side-font">8. Production</h6>
              <p>
                Once accepted, the manuscript moves to the production stage. During this phase, our team ensures the final formatting, proofreading, and copyediting are completed to prepare the manuscript for online publication. Authors are notified of the expected publication timeline.
              </p>
            </li>

            <li>
              <h6 className="side-font">9. Publication</h6>
              <p>
                The manuscript is published online, freely accessible to the global scientific community under our Open Access policy, ensuring maximum visibility and impact.
              </p>
            </li>
          </ol>
        </div>
      </div>
    </section>
        <ContactInformation />
      </div>
      <SubmissionPage />
    </>
  );
};

export default ReviewProcess;
